import React from "react";
import {
  Flex,
  Text,
  VStack,
  HStack,
  Link,
  Hide,
  Skeleton,
  Progress,
  Heading,
  Grid,
  SimpleGrid,
  Box,
  TagLabel,
  Tag,
} from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";
import Card from "ui/Card";
import { OpeningChart } from "components/Status/OpeningChart";
import { MowiIcon } from "ui/MowiIcon/Icon";
import theme from "theme/theme";
import { OpeningProgress } from "components/Status/OpeningProgress";
import { ManagedChart } from "components/Status/ManagedChart";
import { ManagedProgress } from "components/Status/ManagedProgress";
import { AreaStatistics, Statistics } from "libs/apollo/__generated__/graphql";
import { ETgpAreaToName } from "stores/queries/area";
import { PieChart } from "components/Charts/PieChart";

const createAndDownloadCSV = (csv: string, filename: string) => {
  const csvFile = new Blob([csv], { type: "text/csv;charset=utf-8" });
  const downloadLink = document.createElement("a");
  downloadLink.download = filename;
  downloadLink.href = window.URL.createObjectURL(csvFile);
  downloadLink.style.display = "none";
  document.body.appendChild(downloadLink);
  downloadLink.click();
};

type Props = {
  statistics: AreaStatistics["statistics"];
  emailStatistics: AreaStatistics["emailStatistics"];
  googleStatistics: AreaStatistics["googleStatistics"];
  loading?: boolean;
  area?: string;
};

const PoiReport = (props: Props) => {
  const stats = props.statistics;

  const series = [
    stats?.totalOpen | 0,
    stats?.totalClosed | 0,
    stats?.totalNd | 0,
  ];
  const managedSeries = {
    admin: stats?.totalEditedByAdmin | 0,
    operator: stats?.totalEditedByOperator | 0,
    adobe: stats?.totalNotEdited | 0,
  };
  const total = stats?.total | 0;

  const pieChartOptions: any = {
    chart: {
      width: "50px",
    },
    states: {
      hover: {
        filter: {
          type: "none",
        },
      },
    },
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    hover: { mode: null },
    plotOptions: {
      donut: {
        expandOnClick: false,
        donut: {
          labels: {
            show: false,
          },
        },
      },
    },

    tooltip: {
      enabled: true,
      theme: "dark",
    },
  };

  const managingOptions = {
    ...pieChartOptions,
    labels: ["APT", "Operatore", "Adobe"],
    colors: [
      theme.colors.brand[500],
      theme.colors.brand[400],
      theme.colors.gray[400],
    ],
    fill: {
      colors: [
        theme.colors.brand[500],
        theme.colors.brand[400],
        theme.colors.gray[300],
      ],
    },
  };
  const managingData = [
    stats?.totalEditedByAdmin | 0,
    stats?.totalEditedByOperator | 0,
    stats?.totalNotEdited | 0,
  ];

  const openingOptions = {
    ...pieChartOptions,
    labels: ["Aperto", "Chiuso", "N.D."],
    colors: [
      theme.colors.brand[500],
      theme.colors.brand[400],
      theme.colors.gray[400],
    ],
    fill: {
      colors: [
        theme.colors.brand[500],
        theme.colors.brand[400],
        theme.colors.gray[300],
      ],
    },
  };
  const openingData = [
    stats?.totalOpen | 0,
    stats?.totalClosed | 0,
    stats?.totalNd | 0,
  ];

  const termsOptions = {
    ...pieChartOptions,
    labels: ["Accettati", "Non aaccettati"],
    colors: [theme.colors.brand[500], theme.colors.gray[400]],
    fill: {
      colors: [theme.colors.brand[500], theme.colors.gray[300]],
    },
  };
  const termsData = [
    stats?.totalConditionsAccepted | 0,
    (total - (stats?.totalConditionsAccepted | 0)) | 0,
  ];

  const googleOptions = {
    ...pieChartOptions,
    labels: ["Sincronizzato", "No"],
    colors: [theme.colors.brand[500], theme.colors.gray[400]],
    fill: {
      colors: [theme.colors.brand[500], theme.colors.gray[300]],
    },
  };
  const googleData = [
    props?.googleStatistics?.total | 0,
    (total - (props?.googleStatistics?.total | 0)) | 0,
  ];

  return (
    <>
      <Grid
        mb="20px"
        gap={{ base: "20px", xl: "20px" }}
        display={{ base: "block", xl: "grid" }}
      >
        <Flex flexDirection="column">
          <Flex direction="column">
            <Flex
              mt="0px"
              mb="10px"
              direction={{ base: "column", md: "column" }}
              align={{ base: "start", md: "start" }}
            >
              <Flex direction={"row"} align={"center"}>
                <MowiIcon
                  color={theme.colors.gray[500]}
                  name="locator"
                  size={40}
                  style={{ marginLeft: "-10px" }}
                />
                <Flex direction={"column"}>
                  <Link
                    as={RouterLink}
                    to={"/pois"}
                    transition="all 0.2s ease-in-out"
                    _hover={{
                      opacity: 0.6,
                    }}
                  >
                    <Text fontSize="2xl" fontWeight="700">
                      Punti d'Interesse <small>({total})</small>
                    </Text>
                  </Link>
                </Flex>
              </Flex>
            </Flex>
            <SimpleGrid columns={{ base: 1, md: 4 }} gap="20px">
              <Skeleton fadeDuration={1} isLoaded={!props.loading} rounded="xl">
                <Card
                  p="20px"
                  alignItems="center"
                  flexDirection="column"
                  w="100%"
                >
                  <Flex
                    px={{ base: "0px", "2xl": "10px" }}
                    justifyContent="space-between"
                    alignItems="center"
                    w="100%"
                    mb="20px"
                  >
                    <Text fontSize="md" fontWeight="600" mt="4px">
                      Stato attuale
                    </Text>
                  </Flex>
                  <PieChart
                    h="100%"
                    w="100%"
                    chartData={openingData}
                    chartOptions={openingOptions}
                  />

                  <Card
                    //bg={cardColor}
                    flexDirection="row"
                    //boxShadow={cardShadow}
                    justifyContent={"space-between"}
                    w="100%"
                    p="10px"
                    px="15px"
                    mx="auto"
                  >
                    <Flex direction="column" py="5px" align="center">
                      <Tag bg={openingOptions.colors[0]}>
                        <Text fontSize="xs" color="#fff" fontWeight="700">
                          {openingOptions.labels[0]}
                        </Text>
                      </Tag>
                      <Text fontSize="lg" fontWeight="700">
                        {Math.round(((openingData[0] | 0) / total) * 100)}%
                      </Text>
                    </Flex>

                    <Flex direction="column" py="5px" me="10px" align="center">
                      <Tag bg={openingOptions.colors[1]}>
                        <Text fontSize="xs" color="#fff" fontWeight="700">
                          {openingOptions.labels[1]}
                        </Text>
                      </Tag>
                      <Text fontSize="lg" fontWeight="700">
                        {Math.round(((openingData[1] | 0) / total) * 100)}%
                      </Text>
                    </Flex>

                    <Flex direction="column" py="5px" me="10px" align="center">
                      <Tag bg={openingOptions.colors[2]}>
                        <Text fontSize="xs" color="#fff" fontWeight="700">
                          {openingOptions.labels[2]}
                        </Text>
                      </Tag>
                      <Text fontSize="lg" fontWeight="700">
                        {Math.round(((openingData[2] | 0) / total) * 100)}%
                      </Text>
                    </Flex>
                  </Card>
                </Card>
              </Skeleton>

              <Skeleton fadeDuration={1} isLoaded={!props.loading} rounded="xl">
                <Card
                  p="20px"
                  alignItems="center"
                  flexDirection="column"
                  w="100%"
                >
                  <Flex
                    px={{ base: "0px", "2xl": "10px" }}
                    justifyContent="space-between"
                    alignItems="center"
                    w="100%"
                    mb="20px"
                  >
                    <Text fontSize="md" fontWeight="600" mt="4px">
                      Gestione
                    </Text>
                  </Flex>

                  <PieChart
                    h="200"
                    w="100%"
                    chartData={managingData}
                    chartOptions={managingOptions}
                  />
                  <Card
                    //bg={cardColor}
                    flexDirection="row"
                    //boxShadow={cardShadow}
                    justifyContent={"space-between"}
                    w="100%"
                    p="10px"
                    px="15px"
                    mx="auto"
                  >
                    <Flex direction="column" py="5px" align="center">
                      <Tag bg={managingOptions.colors[0]}>
                        <Text fontSize="xs" color="#fff" fontWeight="700">
                          {managingOptions.labels[0]}
                        </Text>
                      </Tag>
                      <Text fontSize="lg" fontWeight="700">
                        {Math.round(((managingData[0] | 0) / total) * 100)}%
                      </Text>
                    </Flex>

                    <Flex direction="column" py="5px" me="10px" align="center">
                      <Tag bg={managingOptions.colors[1]}>
                        <Text fontSize="xs" color="#fff" fontWeight="700">
                          {managingOptions.labels[1]}
                        </Text>
                      </Tag>
                      <Text fontSize="lg" fontWeight="700">
                        {Math.round(((managingData[1] | 0) / total) * 100)}%
                      </Text>
                    </Flex>

                    <Flex direction="column" py="5px" me="10px" align="center">
                      <Tag bg={managingOptions.colors[2]}>
                        <Text fontSize="xs" color="#fff" fontWeight="700">
                          {managingOptions.labels[2]}
                        </Text>
                      </Tag>
                      <Text fontSize="lg" fontWeight="700">
                        {Math.round(((managingData[2] | 0) / total) * 100)}%
                      </Text>
                    </Flex>
                  </Card>
                </Card>
              </Skeleton>

              <Skeleton fadeDuration={1} isLoaded={!props.loading} rounded="xl">
                <Card
                  p="20px"
                  alignItems="center"
                  flexDirection="column"
                  w="100%"
                >
                  <Flex
                    px={{ base: "0px", "2xl": "10px" }}
                    justifyContent="space-between"
                    alignItems="center"
                    w="100%"
                    mb="20px"
                  >
                    <Text fontSize="md" fontWeight="600" mt="4px">
                      Termini e Condizioni
                    </Text>
                  </Flex>

                  <PieChart
                    h="200"
                    w="100%"
                    chartData={termsData}
                    chartOptions={termsOptions}
                  />
                  <Card
                    //bg={cardColor}
                    flexDirection="row"
                    //boxShadow={cardShadow}
                    justifyContent={"space-between"}
                    w="100%"
                    p="10px"
                    px="15px"
                    mx="auto"
                  >
                    <Flex direction="column" py="5px" align="center">
                      <Tag bg={termsOptions.colors[0]}>
                        <Text fontSize="xs" color="#fff" fontWeight="700">
                          {termsOptions.labels[0]}
                        </Text>
                      </Tag>
                      <Text fontSize="lg" fontWeight="700">
                        {Math.round(((termsData[0] | 0) / total) * 100)}%
                      </Text>
                    </Flex>

                    <Flex direction="column" py="5px" me="10px" align="center">
                      <Tag bg={termsOptions.colors[1]}>
                        <Text fontSize="xs" color="#fff" fontWeight="700">
                          {termsOptions.labels[1]}
                        </Text>
                      </Tag>
                      {/* <Flex align="center">
                        <Box
                          h="10px"
                          w="10px"
                          bg={termsOptions.colors[1]}
                          borderRadius="50%"
                          me="4px"
                        />
                        <Text
                          fontSize="xs"
                          color="secondaryGray.600"
                          fontWeight="700"
                        >
                          {termsOptions.labels[1]}
                        </Text>
                      </Flex> */}
                      <Text fontSize="lg" fontWeight="700">
                        {Math.round(((termsData[1] | 0) / total) * 100)}%
                      </Text>
                    </Flex>
                  </Card>
                </Card>
              </Skeleton>

              <Skeleton fadeDuration={1} isLoaded={!props.loading} rounded="xl">
                <Card
                  p="20px"
                  alignItems="center"
                  flexDirection="column"
                  w="100%"
                >
                  <Flex
                    px={{ base: "0px", "2xl": "10px" }}
                    justifyContent="space-between"
                    alignItems="center"
                    w="100%"
                    mb="20px"
                  >
                    <Text fontSize="md" fontWeight="600" mt="4px">
                      Google Sync
                    </Text>
                  </Flex>

                  <PieChart
                    h="200"
                    w="100%"
                    chartData={googleData}
                    chartOptions={googleOptions}
                  />
                  <Card
                    //bg={cardColor}
                    flexDirection="row"
                    //boxShadow={cardShadow}
                    justifyContent={"space-between"}
                    w="100%"
                    p="10px"
                    px="15px"
                    mx="auto"
                  >
                    <Flex direction="column" py="5px" align="center">
                      <Flex align="center">
                        <Tag bg={googleOptions.colors[0]}>
                          <Text fontSize="xs" color="#fff" fontWeight="700">
                            {googleOptions.labels[0]}
                          </Text>
                        </Tag>
                      </Flex>
                      <Text fontSize="lg" fontWeight="700">
                        {Math.round(((googleData[0] | 0) / total) * 100)}%
                      </Text>
                    </Flex>

                    <Flex direction="column" py="5px" me="10px" align="center">
                      <Flex align="center">
                        <Tag bg={googleOptions.colors[1]}>
                          <Text fontSize="xs" color="#fff" fontWeight="700">
                            {googleOptions.labels[1]}
                          </Text>
                        </Tag>
                      </Flex>
                      <Text fontSize="lg" fontWeight="700">
                        {Math.round(((googleData[1] | 0) / total) * 100)}%
                      </Text>
                    </Flex>
                  </Card>
                </Card>
              </Skeleton>
            </SimpleGrid>
          </Flex>
        </Flex>
      </Grid>
    </>
  );

  return (
    <Skeleton fadeDuration={1} isLoaded={!props.loading} rounded="xl">
      <Card
        w="100%"
        h="100%"
        align={{ base: "center", xl: "start" }}
        justify={{ base: "center", xl: "center" }}
        shadow={"none"}
        boxShadow="14px 17px 40px 4px rgba(112, 144, 176, 0.08)"
      >
        <HStack>
          <VStack height={"100%"} align={"start"} width={100}>
            <MowiIcon
              color={theme.colors.gray[200]}
              name="locator"
              size={60}
              style={{ position: "absolute", top: 0, left: 0 }}
              //style={{ marginBottom: "40px", marginTop: 0 }}
            />

            <Link
              as={RouterLink}
              to={"/pois"}
              transition="all 0.2s ease-in-out"
              _hover={{
                opacity: 0.6,
              }}
            >
              <Text fontSize={"2xl"} fontWeight={"bold"} lineHeight={1}>
                P.O.I.
              </Text>
            </Link>

            <Text color={"gray.400"} lineHeight={0.6}>
              Totale: {total}
            </Text>
            <Text color={"gray.400"} mb={"14px"} lineHeight={0.6}>
              Gestiti: {managedSeries.admin + managedSeries.operator}
            </Text>
            {/* <IconButton
              onClick={() => {
                axios({
                  url:
                    ENV.backend.api + `/poi/rootStatisticsByArea/` + props.area,
                  method: "GET",
                  responseType: "blob",
                  headers: {
                    Authorization: "Bearer " + ACTUAL_AUTH.accessToken,
                  },
                }).then((response) => {
                  const url = window.URL.createObjectURL(
                    new Blob([response.data])
                  );
                  const link = document.createElement("a");
                  link.href = url;
                  link.setAttribute("download", props.area + ".csv");
                  document.body.appendChild(link);
                  link.click();
                });
              }}
              style={{ position: "absolute", bottom: 10, left: 10 }}
              icon={<CgExport />}
              aria-label="Export"
              size={"lg"}
            /> */}
          </VStack>
          <VStack flex={1} align={"end"}>
            <Flex w="full">
              {props.area && (
                <Heading
                  size="lg"
                  lineHeight={1}
                  color="tgpBlue"
                  textShadow="xl"
                >
                  {ETgpAreaToName[props.area as keyof typeof ETgpAreaToName]}
                </Heading>
              )}
              <Text
                ml="auto"
                fontSize={"xl"}
                fontWeight={"bold"}
                lineHeight={1}
                mt={2}
              >
                STATO ATTUALE
              </Text>
            </Flex>
            <HStack width={"100%"} borderTopWidth={2}>
              <OpeningProgress series={series} total={total} />
              <Hide below="md">
                <Flex align={"center"} justify="center">
                  <OpeningChart series={series} />
                </Flex>
              </Hide>
            </HStack>
            <Text fontSize={"xl"} fontWeight={"bold"} lineHeight={1} mt={3}>
              GESTITI DA
            </Text>
            <HStack width={"100%"} borderTopWidth={2}>
              <ManagedProgress series={managedSeries} total={total} />
              <Hide below="md">
                <Flex align={"center"} justify="center">
                  <ManagedChart
                    series={[
                      managedSeries.admin,
                      managedSeries.operator,
                      managedSeries.adobe,
                    ]}
                  />
                </Flex>
              </Hide>
            </HStack>

            <Text fontSize={"xl"} fontWeight={"bold"} lineHeight={1} mt={3}>
              TERMINI E CONDIZIONI
            </Text>
            <HStack width={"100%"} borderTopWidth={2} pt={5} mb={2}>
              <Text textAlign={"end"} width={100}>
                Accettati
              </Text>
              <Progress
                width={"100%"}
                colorScheme="blue"
                size="md"
                value={((stats?.totalConditionsAccepted | 0) / total) * 100}
              />
              <Text textAlign={"right"} width={"80px"}>
                {stats?.totalConditionsAccepted | 0}/{total}
              </Text>
            </HStack>

            {/* <Text fontSize={"xl"} fontWeight={"bold"} lineHeight={1} mt={3}>
              ALERT EMAIL
            </Text>
            <HStack width={"100%"} borderTopWidth={2} pt={5} mb={2}>
              <Text textAlign={"end"} width={100}>
                3 Mesi
              </Text>
              <Progress
                width={"100%"}
                colorScheme="blue"
                size="md"
                value={((stats?.totalConditionsAccepted | 0) / total) * 100}
              />
              <Text textAlign={"right"} width={"80px"}>
                {stats?.totalConditionsAccepted | 0}/{total}
              </Text>
            </HStack>

            <HStack width={"100%"} borderTopWidth={2} pt={5} mb={2}>
              <Text textAlign={"end"} width={100}>
                3 Mesi
              </Text>
              <Progress
                width={"100%"}
                colorScheme="blue"
                size="md"
                value={((stats?.totalConditionsAccepted | 0) / total) * 100}
              />
              <Text textAlign={"right"} width={"80px"}>
                {stats?.totalConditionsAccepted | 0}/{total}
              </Text>
            </HStack> */}
          </VStack>
        </HStack>
      </Card>
    </Skeleton>
  );
};

export { PoiReport };
