import { useQuery } from "@apollo/client";
import {
  Box,
  Flex,
  Heading,
  HStack,
  IconButton,
  Spinner,
  Text,
  Tooltip,
  VStack,
} from "@chakra-ui/react";
import { PoiReport } from "components/Poi/PoiReport";
import React from "react";
import { ACCOMODATIONS_GET_ALLAREAS_STATS } from "stores/queries/accomodations";
import { ETgpAreaToName } from "stores/queries/area";
import { POIS_GET_ALLAREAS_STATS } from "stores/queries/pois";
import { Loading } from "ui/Loading/Loading";
import { Tabs, TabList, TabPanels, Tab, TabPanel } from "@chakra-ui/react";
import { AccomodationReport } from "components/Accomodation/AccomodationReport";
import { CgExport } from "react-icons/cg";
import axios from "axios";
import { ACTUAL_AUTH } from "stores/db/auth";
import { ENV } from "config";

const AdminDashboard = () => {
  const { data: poiStatsData, loading: poiLoading } = useQuery(
    POIS_GET_ALLAREAS_STATS
  );
  const { data: accomodationStatsData, loading: accomodationLoading } =
    useQuery(ACCOMODATIONS_GET_ALLAREAS_STATS);

  console.log("STATS:", accomodationStatsData);

  const [exportLoading, setExportLoading] = React.useState(false);

  return (
    <Box w="full">
      <VStack w={"full"} spacing={0}>
        <Tabs w={"full"}>
          <TabList>
            <Tab>
              <HStack alignItems={"center"} justifyContent={"center"}>
                <Text fontWeight={"bold"}>Punti d'interesse</Text>
                <Tooltip label="Esporta Dati">
                  <IconButton
                    cursor={"pointer"}
                    onClick={() => {
                      if (exportLoading) return;
                      setExportLoading(true);
                      axios({
                        url: ENV.backend.api + `/poi/rootStatisticsAll/`,
                        method: "GET",
                        responseType: "blob",
                        headers: {
                          Authorization: "Bearer " + ACTUAL_AUTH.accessToken,
                        },
                      }).then((response) => {
                        setExportLoading(false);
                        const url = window.URL.createObjectURL(
                          new Blob([response.data])
                        );
                        const link = document.createElement("a");
                        link.href = url;
                        link.setAttribute(
                          "download",
                          "allareas_" + new Date().toLocaleDateString() + ".csv"
                        );
                        document.body.appendChild(link);
                        link.click();
                      });
                    }}
                    size={"sm"}
                    ml={0}
                    variant={"ghost"}
                    icon={
                      exportLoading ? <Spinner size={"sm"} /> : <CgExport />
                    }
                    aria-label="Export"
                  />
                </Tooltip>
              </HStack>
            </Tab>
            <Tab>
              <HStack alignItems={"center"} justifyContent={"center"}>
                <Text fontWeight={"bold"}>Strutture Ricettive</Text>
                <Tooltip label="Esporta Dati">
                  <IconButton
                    cursor={"pointer"}
                    onClick={() => {
                      if (exportLoading) return;
                      setExportLoading(true);
                      axios({
                        url:
                          ENV.backend.api + `/accomodation/rootStatisticsAll/`,
                        method: "GET",
                        responseType: "blob",
                        headers: {
                          Authorization: "Bearer " + ACTUAL_AUTH.accessToken,
                        },
                      }).then((response) => {
                        setExportLoading(false);
                        const url = window.URL.createObjectURL(
                          new Blob([response.data])
                        );
                        const link = document.createElement("a");
                        link.href = url;
                        link.setAttribute(
                          "download",
                          "allareas_" + new Date().toLocaleDateString() + ".csv"
                        );
                        document.body.appendChild(link);
                        link.click();
                      });
                    }}
                    size={"sm"}
                    ml={0}
                    variant={"ghost"}
                    icon={
                      exportLoading ? <Spinner size={"sm"} /> : <CgExport />
                    }
                    aria-label="Export"
                  />
                </Tooltip>
              </HStack>
            </Tab>
          </TabList>

          <TabPanels>
            <TabPanel>
              {poiLoading && !poiStatsData?.poiGetAllAreasStatistics && (
                <Flex
                  mt={20}
                  w={"100%"}
                  alignContent={"center"}
                  justifyContent={"center"}
                >
                  <Spinner
                    thickness="4px"
                    speed="0.65s"
                    emptyColor="gray.200"
                    color="brand.500"
                    size="xl"
                  />
                </Flex>
              )}
              {poiStatsData?.poiGetAllAreasStatistics?.map((area) => {
                return (
                  <>
                    {area?.area && area?.statistics?.total ? (
                      <Box pt={10} pb={3}>
                        <Heading
                          size="lg"
                          lineHeight={1}
                          color="tgpBlue"
                          textShadow="xl"
                          ml={2}
                        >
                          {
                            ETgpAreaToName[
                              area?.area as keyof typeof ETgpAreaToName
                            ]
                          }
                        </Heading>
                      </Box>
                    ) : null}
                    {area?.statistics?.total ? (
                      <PoiReport
                        key={area?.area}
                        statistics={area?.statistics}
                        emailStatistics={area?.emailStatistics}
                        googleStatistics={area?.googleStatistics}
                        loading={poiLoading}
                        area={area?.area}
                      />
                    ) : null}
                  </>
                );
              })}
            </TabPanel>
            <TabPanel>
              {accomodationLoading &&
                !accomodationStatsData?.accomodationGetAllAreasStatistics && (
                  <Flex
                    mt={20}
                    w={"100%"}
                    alignContent={"center"}
                    justifyContent={"center"}
                  >
                    <Spinner
                      thickness="4px"
                      speed="0.65s"
                      emptyColor="gray.200"
                      color="brand.500"
                      size="xl"
                    />
                  </Flex>
                )}
              {accomodationStatsData?.accomodationGetAllAreasStatistics?.map(
                (area) => {
                  return (
                    <>
                      {area?.area && area?.statistics?.total ? (
                        <Heading
                          size="lg"
                          lineHeight={1}
                          color="tgpBlue"
                          textShadow="xl"
                          mt={3}
                          ml={2}
                        >
                          {
                            ETgpAreaToName[
                              area?.area as keyof typeof ETgpAreaToName
                            ]
                          }
                        </Heading>
                      ) : null}
                      {area?.statistics?.total ? (
                        <AccomodationReport
                          key={area?.area}
                          statistics={area?.statistics}
                          emailStatistics={area?.emailStatistics}
                          loading={accomodationLoading}
                          area={area?.area}
                        />
                      ) : null}
                    </>
                  );
                }
              )}
            </TabPanel>
          </TabPanels>
        </Tabs>
      </VStack>
    </Box>
  );
};

export { AdminDashboard };
