import React from "react";
import { useMutation, useQuery } from "@apollo/client";

import {
  POIS,
  POIS_CALENDAR_ADDRANGE,
  POIS_GET_QR_EMAIL,
  POIS_MIN,
  POIS_SECURE_MANAGE,
} from "stores/queries/pois";
import { ResourceTable } from "components/List/ResourceTable";

const PoiTable = () => {
  const { loading, data } = useQuery(POIS_MIN);

  const [addRange] = useMutation(POIS_CALENDAR_ADDRANGE, {
    refetchQueries: [POIS],
    //awaitRefetchQueries: true,
  });

  const [secureManage] = useMutation(POIS_SECURE_MANAGE, {
    refetchQueries: [{ query: POIS }],
  });

  const [getQrEmail, { loading: gqeLoading }] = useMutation(POIS_GET_QR_EMAIL);

  return (
    <ResourceTable
      key={"resource_pois"}
      data={data?.adminPois}
      loading={loading}
      addRange={addRange}
      secureManage={secureManage}
      getQrEmail={getQrEmail}
      title={"Punti d'interesse"}
      itemPath={"/pois/"}
      icon="locator"
      type="poi"
    />
  );
};

export { PoiTable };
