import QRCode from "react-qr-code";

import React, { useEffect, useState } from "react";

import {
  Flex,
  Text,
  VStack,
  Box,
  Switch,
  Button,
  Input,
  Divider,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  useDisclosure,
} from "@chakra-ui/react";

import Card from "ui/Card";
import { CalendarIcon, CheckIcon, EmailIcon, TimeIcon } from "@chakra-ui/icons";
import { Loading } from "ui/Loading/Loading";
import { ApolloMutation, Resource } from "stores/queries/area";

const downloadPDF = (pdf: string, name: string) => {
  const linkSource = `data:application/pdf;base64,${pdf}`;
  const downloadLink = document.createElement("a");
  const fileName = "QR-TM_" + name + ".pdf";

  downloadLink.href = linkSource;
  downloadLink.download = fileName;
  downloadLink.click();
};

type GetByIdMutation = ({
  variables,
}: {
  variables: {
    _id: string;
  };
}) => Promise<any>;
type GetByIdsMutation = ({
  variables,
}: {
  variables: {
    _ids: string | string[];
  };
}) => Promise<any>;
type SecureManageMutation = ({
  variables,
}: {
  variables: {
    _ids: string | string[];
    secure: {
      reNewCode: boolean;
      permissions: {
        manual: boolean;
        calendar: boolean;
      };
    };
  };
}) => Promise<any>;

interface OperatorLinkProps {
  _id: string;
  resource: Resource;
  type: "track" | "poi" | "transport" | "accomodation";
  secureManage: SecureManageMutation;
  getQrPdf: GetByIdMutation;
  getQrEmail: GetByIdsMutation;
  loading: boolean;
}

const OperatorLink = (props: OperatorLinkProps) => {
  const [permissions, setPermissions] = useState(
    props.type !== "accomodation"
      ? {
          calendar: props.resource?.secure?.permissions?.calendar,
          manual: props.resource?.secure?.permissions?.manual,
        }
      : undefined
  );

  const [url, setUrl] = useState(window.location.origin);

  const {
    isOpen: isOpenSuccess,
    onOpen: onOpenSuccess,
    onClose: onCloseSuccess,
  } = useDisclosure();

  useEffect(() => {
    if (props.resource?.secure?.permissions && props.type !== "accomodation") {
      setPermissions({
        calendar: props.resource?.secure.permissions.calendar,
        manual: props.resource?.secure.permissions.manual,
      });
    }
  }, [props.resource?.secure]);

  useEffect(() => {
    if (props.resource && props.type && props._id) {
      setUrl(
        window.location.origin +
          `/?s=${props.resource?.secure?.code}&t=${props.type}&i=${props._id}`
      );
    }
  }, [props.resource, props.type, props._id]);

  return (
    <Card
      w="100%"
      h="100%"
      align={{ base: "center", xl: "center" }}
      justify={{ base: "center", xl: "center" }}
    >
      <Flex flex={1} flexDirection={"row"}>
        <Flex flex={1} flexDirection="column" p={2}>
          <Text fontSize="3xl" color="gray.800" fontWeight={"bold"}>
            Link operatore
          </Text>

          <Text color="gray.600">
            Il link e il Qr code qui riportati, permettono all’attività
            interessata di gestire autonomamente, in modo facile e veloce, la
            calendarizzazione delle aperture e chiusure relative alla propria
            attività. Questo sia da mobile che da desktop.
          </Text>

          <Flex
            flexDirection={"row"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <Input
              id={"op_link"}
              disabled
              opacity={"1 !important"}
              value={url}
            />
            <Button
              m={2}
              colorScheme={"blackAlpha"}
              size={"md"}
              variant="outline"
              onClick={() => {
                const copyText = document.getElementById(
                  "op_link"
                ) as HTMLInputElement;
                copyText.select();
                copyText.setSelectionRange(0, 99999);
                navigator?.clipboard?.writeText(copyText.value);
              }}
            >
              Copia Link
            </Button>
          </Flex>

          {permissions && (
            <>
              <Text fontSize="3xl" color="gray.800" fontWeight={"bold"}>
                Permessi
              </Text>
              <Text color="gray.600">
                Attiva di seguito i permessi che intendi concedere all’operatore
                di questa attività, per la gestione delle aperture, chiusure e
                relativi orari.
              </Text>
              <Flex mt={6} px={2} flexDirection={"row"} alignItems={"center"}>
                <TimeIcon fontSize={20} mr={2} />
                <Flex mx={2} flex={1} flexDirection={"column"}>
                  <Text fontSize={"xs"} color="gray.600" fontWeight={"bold"}>
                    REAL-TIME - MANUALE
                  </Text>
                  <Text fontSize={"xs"} color="gray.600">
                    Gestione manuale, di aperture e chiusure straordinarie in
                    tempo reale
                  </Text>
                </Flex>
                <Switch
                  isChecked={permissions.manual}
                  onChange={(e) => {
                    props.secureManage({
                      variables: {
                        _ids: [props._id],
                        secure: {
                          reNewCode: false,
                          permissions: {
                            calendar: permissions.calendar,
                            manual: e.target.checked,
                          },
                        },
                      },
                    });
                    setPermissions({
                      ...permissions,
                      manual: e.target.checked,
                    });
                  }}
                  mx={4}
                  size={"lg"}
                  colorScheme={"brand"}
                />
              </Flex>
              <Flex mt={4} px={2} flexDirection={"row"} alignItems={"center"}>
                <CalendarIcon fontSize={20} mr={2} />

                <Flex mx={2} flex={1} flexDirection={"column"}>
                  <Text fontSize={"xs"} color="gray.600" fontWeight={"bold"}>
                    CALENDARIO
                  </Text>
                  <Text fontSize={"xs"} color="gray.600">
                    Gestione calendarizzata di aperture/chiusure attività
                  </Text>
                </Flex>
                <Switch
                  isChecked={permissions.calendar}
                  onChange={(e) => {
                    props.secureManage({
                      variables: {
                        _ids: [props._id],
                        secure: {
                          reNewCode: false,
                          permissions: {
                            manual: permissions.manual,
                            calendar: e.target.checked,
                          },
                        },
                      },
                    });
                    setPermissions({
                      ...permissions,
                      calendar: e.target.checked,
                    });
                  }}
                  mx={4}
                  size={"lg"}
                  colorScheme={"brand"}
                />
              </Flex>
            </>
          )}
          <Divider style={{ marginTop: 30, marginBottom: 10 }} />
          <Text color="gray.600">
            Privacy Policy e Termini di servizio{" "}
            {props.resource?.termsAndConditions?.version && (
              <b>v. {props.resource?.termsAndConditions?.version}</b>
            )}{" "}
            accettate in data:{" "}
            <b>
              {props.resource?.termsAndConditions?.accepted
                ? new Date(
                    props.resource.termsAndConditions.accepted
                  ).toLocaleDateString()
                : "Non accettati"}
            </b>
          </Text>
          {/*           {props.resource?.termsAndConditions?.accepted &&
          props.resource?.termsAndConditions?.newVersion !==
            props.resource?.termsAndConditions?.version ? (
            <Text color="red.600">
              Nuove Privacy Policy e Termini di servizio (
              <b>v. {props.resource?.termsAndConditions?.newVersion}</b>) non
              ancora accettate
            </Text>
          ) : null} */}

          <Text color="gray.600">
            Ultimo accesso operatore:{" "}
            <b>
              {props.resource?.calendarMetadata?.lastOperatorCheck
                ? new Date(
                    props.resource?.calendarMetadata?.lastOperatorCheck
                  ).toLocaleDateString()
                : "Mai"}
            </b>
          </Text>

          <Divider style={{ marginTop: 10, marginBottom: 10 }} />

          {props.resource?.calendarMetadata?.emptyCalendarAlertSent && (
            <Text color="gray.600">
              Alert calendario prossimo mese vuoto inviato in data:{" "}
              <b>
                {new Date(
                  props.resource?.calendarMetadata?.emptyCalendarAlertDate
                ).toLocaleDateString()}
              </b>
            </Text>
          )}
          {props.resource?.calendarMetadata?.threeMonthAlertSent ? (
            <Text color="gray.600">
              Alert accesso operatore più di 3 mesi fa inviato in data:{" "}
              <b>
                {new Date(
                  props.resource?.calendarMetadata?.threeMonthAlertDate
                ).toLocaleDateString()}
              </b>
            </Text>
          ) : null}
        </Flex>
        <Flex
          bgColor={"#fff"}
          justifyContent={"center"}
          alignItems={"center"}
          flexDirection="column"
          p={2}
        >
          <Box
            borderRadius={20}
            borderColor={"brand.500"}
            borderWidth={10}
            p={5}
          >
            <QRCode size={180} width={"150px"} value={url} />
          </Box>

          <VStack m={1} mt={2} alignItems={"flex-start"}>
            <Button
              w={200}
              variant="outline"
              colorScheme={"brand"}
              size={"md"}
              onClick={() => {
                props
                  .getQrPdf({ variables: { _id: props._id } })
                  .then((res) => {
                    let data = null;

                    switch (props.type) {
                      case "poi":
                        data = res?.data?.adminPoiGetQrCodePdf;
                        break;
                      case "accomodation":
                        data = res?.data?.adminAccomodationGetQrCodePdf;
                        break;
                    }

                    if (data) {
                      const name =
                        props.resource.name?.find((n) => n.langIso === "it")
                          ?.value ||
                        props.resource.name[0]?.value ||
                        "";
                      downloadPDF(data, name.replace(/ /g, "_").toLowerCase());
                    } else {
                      console.warn("No pdf found " + props._id);
                    }
                  });
              }}
            >
              Download
            </Button>
          </VStack>
          <VStack m={1} alignItems={"flex-start"}>
            <Button
              w={200}
              variant="outline"
              colorScheme={"brand"}
              size={"md"}
              onClick={() => {
                props
                  .getQrEmail({ variables: { _ids: [props._id] } })
                  .then((res) => {
                    onOpenSuccess();
                  });
              }}
            >
              Inoltra <EmailIcon ml={2} />
            </Button>
          </VStack>
          <VStack m={1} alignItems={"flex-start"}>
            <Button
              w={200}
              colorScheme={"brand"}
              size={"md"}
              onClick={() => {
                let secure: any = {
                  reNewCode: true,
                };

                if (permissions) {
                  secure["permissions"] = {
                    calendar: permissions.calendar,
                    manual: permissions.manual,
                  };
                }

                props
                  .secureManage({
                    variables: {
                      _ids: [props._id],
                      secure,
                    },
                  })
                  .then((res) => {
                    console.log(res);
                  });
              }}
            >
              Genera nuovo Link
            </Button>
          </VStack>
        </Flex>
      </Flex>
      <Modal isOpen={isOpenSuccess} onClose={onCloseSuccess}>
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalBody w={"100%"} alignItems={"center"} justifyContent={"center"}>
            <Flex
              mt={4}
              px={2}
              w={"100%"}
              flexDirection={"column"}
              alignItems={"center"}
              justifyContent={"center"}
            >
              <Text
                fontSize={"xl"}
                fontWeight={"bold"}
                mt={4}
                textAlign={"center"}
              >
                Email inoltrata correttamente all'operatore
              </Text>
              <CheckIcon ml={2} fontSize={"8xl"} color="green.500" />
            </Flex>
          </ModalBody>

          <ModalFooter>
            <Button
              colorScheme="brand"
              onClick={() => {
                onCloseSuccess();
              }}
            >
              Ok
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      {props.loading && <Loading />}
    </Card>
  );
};

export { OperatorLink };
