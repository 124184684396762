import React, { useState } from "react";
import { useMutation, useQuery } from "@apollo/client";

import {
  POIS,
  POIS_AREA_MIN_RO,
  POIS_CALENDAR_ADDRANGE,
  POIS_GET_QR_EMAIL,
  POIS_MIN,
  POIS_SECURE_MANAGE,
} from "stores/queries/pois";
import { ResourceTable } from "components/List/ResourceTable";
import { ACTUAL_AUTH } from "stores/db/auth";
import { secureInfos } from "stores/db/appstate";
import {
  Button,
  Circle,
  Flex,
  HStack,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Text,
  VStack,
  useDisclosure,
} from "@chakra-ui/react";
import { MowiIcon } from "ui/MowiIcon/Icon";
import { CalendarIcon } from "@chakra-ui/icons";
import Calendar from "react-calendar";
import { DateRangeType, Resource } from "stores/queries/area";
import { ResourceTableInfos } from "components/List/ResourceTableInfos";

interface PoiTableInfosProps {
  area: string;
}
const PoiTableInfos = (props: PoiTableInfosProps) => {
  const [date, setDate] = useState<Date>(new Date());

  const { loading, data } = useQuery(POIS_AREA_MIN_RO, {
    context: { headers: { areaSecureCode: `${secureInfos()}` } },
    variables: {
      area: props.area,
      date,
      limit: 999,
    },
  });

  const [getQrEmail, { loading: gqeLoading }] = useMutation(POIS_GET_QR_EMAIL);

  const {
    isOpen: isCalOpen,
    onOpen: calOpen,
    onClose: calClose,
  } = useDisclosure();

  const onChangeStartRange = (date: any) => {
    setDate(date);
    calClose();
  };

  return (
    <VStack
      flex={1}
      style={{ padding: 20, paddingTop: 0, alignItems: "center" }}
    >
      <Flex
        flexDirection={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
        width={"100%"}
        p={4}
        py={4}
        pb={2}
      >
        <Flex flex={1} flexDirection={"row"} alignItems={"center"}>
          <Circle bgGradient="linear(to-br, brand.300, brand.500)" size={34}>
            <MowiIcon color="white" name={"info_point"} size={24} />
          </Circle>
          <Flex flexDirection={"column"} ml={3}>
            <Text
              textTransform={"capitalize"}
              fontSize={12}
              colorScheme={"gray"}
              lineHeight={1.2}
            >
              Lista aperture
            </Text>
            <Text fontSize={18} fontWeight={"bold"} lineHeight={1.2}>
              Info Point
            </Text>
          </Flex>
        </Flex>

        <VStack mx={2}>
          <Popover isOpen={isCalOpen} onOpen={calOpen} onClose={calClose}>
            <PopoverTrigger>
              <Button
                style={{ textTransform: "capitalize" }}
                variant="outline"
                size={"lg"}
                leftIcon={<CalendarIcon />}
              >
                {new Intl.DateTimeFormat("it-IT", {
                  weekday: "long",
                  day: "2-digit",
                  month: "long",
                  year: "numeric",
                }).format(date)}
              </Button>
            </PopoverTrigger>
            <PopoverContent>
              <PopoverArrow />
              <PopoverCloseButton />
              <PopoverHeader>Imposta Data</PopoverHeader>
              <PopoverBody>
                <Calendar
                  minDate={new Date()}
                  onClickDay={(da) => {
                    let newDate = da.setTime(
                      da.getTime() + 12 * 60 * 60 * 1000
                    );
                    console.log("CALENDAR NEWDATE:", newDate);
                    onChangeStartRange(new Date(newDate));
                  }}
                  value={date}
                />
              </PopoverBody>
            </PopoverContent>
          </Popover>
        </VStack>
      </Flex>
      <ResourceTableInfos
        data={
          data?.roPoisByArea as any as {
            poi: Resource;
            dailyTimehours: string[];
            weekOpeningDays: boolean[];
          }[]
        }
        date={date.toISOString()}
        loading={loading}
        getQrEmail={getQrEmail}
        title={"Punti d'interesse"}
        itemPath={"/pois/"}
        icon="locator"
        type="poi"
        area={props.area}
      />
    </VStack>
  );
};

export { PoiTableInfos };
