import React, { useEffect, useState } from "react";

import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import {
  ACCOMODATION,
  ACCOMODATION_ACCEPT_TERMS,
  ACCOMODATION_DAYS,
  /*   ACCOMODATION_ACCEPT_TERMS,
  ACCOMODATION_CANCEL_GOOGLE_SYNC_OPERATOR, */
  ACCOMODATION_OPERATOR,
  ACCOMODATIONS_CALENDAR_ADDRANGE,
} from "stores/queries/accomodations";
import { secure } from "stores/db/appstate";
import { CalendarDay } from "stores/queries/area";
import { OpeningsView } from "components/Operator/OpeningsView";
import { toCalendarDays } from "libs/helpers";
import { useToast } from "@chakra-ui/react";

type AccomodationOperatorProps = {
  _id: string;
};

const AccomodationOperator = (props: AccomodationOperatorProps) => {
  const [permissions, setPermissions] = useState();

  const toast = useToast();

  const successToast = (message = "Operazione completata") =>
    toast({
      title: message,
      status: "success",
      duration: 5000,
      isClosable: true,
    });

  const { loading, data } = useQuery(ACCOMODATION_OPERATOR, {
    variables: { id: props._id },
    context: { headers: { secureCode: `${secure()}` } },
    fetchPolicy: "no-cache",
    skip: !props._id || !secure(),
  });

  const [getCalendar, { loading: gcLoading }] = useLazyQuery(
    ACCOMODATION_DAYS,
    {
      context: { headers: { secureCode: `${secure()}` } },
      fetchPolicy: "network-only",
    }
  );
  const getDays = async (data: {
    variables: {
      from: string;
      to: string;
      _id: string;
    };
  }) => {
    let days = null;

    try {
      const res = await getCalendar({
        variables: {
          from: data.variables.from,
          to: data.variables.to,
          id: data.variables._id,
        },
      });

      days = toCalendarDays(
        data.variables.from,
        res?.data?.adminAccomodationGetDays
      );
    } catch (error) {
      console.log(error);
    }

    return days;
  };

  const [addRange, { loading: aRLoading, error: arError, data: arData }] =
    useMutation(ACCOMODATIONS_CALENDAR_ADDRANGE, {
      context: { headers: { secureCode: `${secure()}` } },
      onCompleted: (data) => {
        let length = data.adminAccomodationCalendarAddRange.length;
        successToast(length + " strutture modificate!");
      },
    });

  const [acceptTerms, { loading: atLoading }] = useMutation(
    ACCOMODATION_ACCEPT_TERMS,
    {
      refetchQueries: [ACCOMODATION_OPERATOR],
      awaitRefetchQueries: true,
      context: { headers: { secureCode: `${secure()}` } },
    }
  );

  /*const [cancelGoogleSync, { loading: cgLoading }] = useMutation(
    ACCOMODATION_CANCEL_GOOGLE_SYNC_OPERATOR,
    {
      refetchQueries: [ACCOMODATION_OPERATOR],
      awaitRefetchQueries: true,
      context: { headers: { secureCode: `${secure()}` } },
    }
  ); */

  const cancelGoogleSync: any = () => {};

  return (
    <OpeningsView
      _id={props._id}
      loading={loading || gcLoading || aRLoading || atLoading}
      addRange={addRange}
      getDays={getDays}
      icon="my_hotel"
      title={"Struttura Ricettiva"}
      name={
        data?.operatorAccomodation?.name?.find((n) => n.langIso === "it")
          ?.value ||
        data?.operatorAccomodation?.name[0]?.value ||
        ""
      }
      permissions={permissions}
      secureRefresh={
        data?.operatorAccomodation?.secure?.lastRefresh
          ? new Date(data.operatorAccomodation.secure.lastRefresh).getTime()
          : 0
      }
      termsAccepted={
        data?.operatorAccomodation?.termsAndConditions?.accepted
          ? new Date(
              data.operatorAccomodation.termsAndConditions.accepted
            ).getTime()
          : 0
      }
      termsVersion={{
        version: data?.operatorAccomodation?.termsAndConditions?.version,
        newVersion: data?.operatorAccomodation?.termsAndConditions?.newVersion,
      }}
      acceptTerms={acceptTerms}
      cancelGoogleSync={cancelGoogleSync}
      calendarMeta={data?.operatorAccomodation?.calendarMetadata}
      calendarSync={data?.operatorAccomodation?.calendarSync}
    />
  );
};

export { AccomodationOperator };
