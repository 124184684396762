import React from "react";
import { Box, Text } from "@chakra-ui/react";
import { PoiTableInfos } from "components/InfoPoint/PoiTableInfos";
import { PoiCalendarInfos } from "components/InfoPoint/PoiCalendarInfos";
import { Tabs, TabList, TabPanels, Tab, TabPanel } from "@chakra-ui/react";
import { AccomodationOpeningInfos } from "components/InfoPoint/AccomodationOpeningInfos";

const ViewInfos = () => {
  let urlParams = new URLSearchParams(window.location.search);
  let type = urlParams.get("t");
  let area = urlParams.get("a");
  let _id = urlParams.get("_id");

  if (_id && type === "poi") {
    return <PoiCalendarInfos _id={_id} area={area} />;
  }

  return (
    <Box w={"100%"}>
      <Tabs>
        <TabList mx={5} mt={2}>
          <Tab>
            <Text fontWeight={"bold"}>Punti d'interesse</Text>
          </Tab>
          <Tab>
            <Text fontWeight={"bold"}>Strutture Ricettive</Text>
          </Tab>
        </TabList>

        <TabPanels p={0}>
          <TabPanel p={0}>
            <PoiTableInfos area={area} />
          </TabPanel>
          <TabPanel>
            <AccomodationOpeningInfos area={area} />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
};

export { ViewInfos };
