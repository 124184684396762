import {
  CalendarIcon,
  ChevronRightIcon,
  DeleteIcon,
  SmallAddIcon,
} from "@chakra-ui/icons";
import {
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverArrow,
  PopoverCloseButton,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  useDisclosure,
  Text,
  Stack,
  VStack,
  Button,
  Divider,
  IconButton,
  Circle,
  Box,
  HStack,
  Input,
} from "@chakra-ui/react";
import { AddRangeMutation } from "components/Poi/types";
import { CalendarRangeInput } from "libs/apollo/__generated__/graphql";
import { formatDate } from "libs/helpers";
import { useEffect, useState } from "react";
import { Calendar } from "react-calendar";
import { ApolloMutation, DateRangeType } from "stores/queries/area";

interface AddRangeDrawerProps {
  _ids: string[];
  addRange: AddRangeMutation;
  refresh?: () => void;
  onFinish?: () => void;
  open: boolean;
  onClosed: () => void;
  calendarSelection?: { start: Date; end: Date } | null;
  disableHours?: boolean;
}

const AddRangeDrawer = (props: AddRangeDrawerProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  //const [isOpen, setIsOpen] = useState(false);

  const {
    isOpen: isFromOpen,
    onOpen: fromOpen,
    onClose: fromClose,
  } = useDisclosure();
  const {
    isOpen: isToOpen,
    onOpen: toOpen,
    onClose: toClose,
  } = useDisclosure();

  const [operation, setOperation] = useState<0 | 1 | 2>(0);

  const [dateRange, setDateRange] = useState<DateRangeType>({
    start: new Date(new Date().getTime()),
    end: new Date(new Date().getTime() + 1000 * 60 * 60 * 24 * 7),
  });

  const onChangeStartRange = (date: any) => {
    if (date.getTime() > dateRange.end.getTime()) {
      setDateRange({ start: date, end: date });
    } else setDateRange({ start: date, end: dateRange.end });
    fromClose();
    toOpen();
  };

  const [timesRange, setTimesRange] = useState<[string, string][]>([]);

  const [weekInput, setWeekInput] = useState({
    sunday: true,
    monday: true,
    tuesday: true,
    wednesday: true,
    thursday: true,
    friday: true,
    saturday: true,
  });

  useEffect(() => {
    if (!props.open) {
      onClose();
      return;
    }
    setTimesRange([]);
    setWeekInput({
      sunday: true,
      monday: true,
      tuesday: true,
      wednesday: true,
      thursday: true,
      friday: true,
      saturday: true,
    });
    if (props.calendarSelection) setDateRange(props.calendarSelection);
    else
      setDateRange({
        start: new Date(new Date().getTime()),
        end: new Date(new Date().getTime() + 1000 * 60 * 60 * 24 * 7),
      });
    setOperation(0);
    onOpen();
  }, [props.open]);

  return (
    <Drawer
      isOpen={isOpen}
      size={"lg"}
      placement="right"
      onClose={() => {
        props.onClosed();
        onClose();
      }}
    >
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader fontSize={30}>Modifica in blocco</DrawerHeader>

        <DrawerBody>
          {props._ids.length > 1 && (
            <Text mt={2} mb={4} fontSize="sm" fontWeight={"bold"}>
              {props._ids.length} elementi selezionati
            </Text>
          )}
          <Text mt={2} mb={3} fontSize="xs">
            Seleziona il periodo
          </Text>
          <Stack
            alignItems={"center"}
            direction={["column", "row"]}
            //spacing="24px"
            mb={5}
          >
            <VStack alignItems={"center"}>
              <Popover
                isOpen={isFromOpen}
                onOpen={fromOpen}
                onClose={fromClose}
              >
                <PopoverTrigger>
                  <Button variant="outline" leftIcon={<CalendarIcon />}>
                    {dateRange.start.toLocaleDateString()}
                  </Button>
                </PopoverTrigger>
                <PopoverContent>
                  <PopoverArrow />
                  <PopoverCloseButton />
                  <PopoverHeader>A partire dal</PopoverHeader>
                  <PopoverBody>
                    <Calendar
                      minDate={new Date(new Date())}
                      onChange={onChangeStartRange}
                      value={dateRange.start}
                    />
                  </PopoverBody>
                </PopoverContent>
              </Popover>
            </VStack>

            <Text fontSize="xs" as="b">
              -
            </Text>

            <VStack>
              <Popover isOpen={isToOpen} onOpen={toOpen} onClose={toClose}>
                <PopoverTrigger>
                  <Button variant="outline" leftIcon={<CalendarIcon />}>
                    {dateRange.end.toLocaleDateString()}
                  </Button>
                </PopoverTrigger>
                <PopoverContent>
                  <PopoverArrow />
                  <PopoverCloseButton />
                  <PopoverHeader>Fino al</PopoverHeader>
                  <PopoverBody>
                    <Calendar
                      minDate={dateRange.start}
                      onChange={(date: any) => {
                        setDateRange({ end: date, start: dateRange.start });
                        toClose();
                      }}
                      value={dateRange.end}
                    />
                  </PopoverBody>
                </PopoverContent>
              </Popover>
            </VStack>
          </Stack>
          <Divider />
          {!props.disableHours && (
            <>
              <Text mt={6} mb={3} fontSize="xs">
                Seleziona i giorni della settimana
              </Text>
              <Stack direction={["column", "row"]} spacing="24px" mt={5} mb={5}>
                <IconButton
                  onClick={() => {
                    setWeekInput({ ...weekInput, monday: !weekInput.monday });
                  }}
                  isRound
                  aria-label="Search database"
                  icon={
                    <Circle
                      size="40px"
                      bg={weekInput.monday ? "brand.300" : "gray.300"}
                      color="white"
                    >
                      LU
                    </Circle>
                  }
                />
                <IconButton
                  onClick={() => {
                    setWeekInput({
                      ...weekInput,
                      tuesday: !weekInput.tuesday,
                    });
                  }}
                  isRound
                  aria-label="Search database"
                  icon={
                    <Circle
                      size="40px"
                      bg={weekInput.tuesday ? "brand.300" : "gray.300"}
                      color="white"
                    >
                      MA
                    </Circle>
                  }
                />
                <IconButton
                  onClick={() => {
                    setWeekInput({
                      ...weekInput,
                      wednesday: !weekInput.wednesday,
                    });
                  }}
                  isRound
                  aria-label="Search database"
                  icon={
                    <Circle
                      size="40px"
                      bg={weekInput.wednesday ? "brand.300" : "gray.300"}
                      color="white"
                    >
                      ME
                    </Circle>
                  }
                />
                <IconButton
                  onClick={() => {
                    setWeekInput({
                      ...weekInput,
                      thursday: !weekInput.thursday,
                    });
                  }}
                  isRound
                  aria-label="Search database"
                  icon={
                    <Circle
                      size="40px"
                      bg={weekInput.thursday ? "brand.300" : "gray.300"}
                      color="white"
                    >
                      GI
                    </Circle>
                  }
                />
                <IconButton
                  onClick={() => {
                    setWeekInput({ ...weekInput, friday: !weekInput.friday });
                  }}
                  isRound
                  aria-label="Search database"
                  icon={
                    <Circle
                      size="40px"
                      bg={weekInput.friday ? "brand.300" : "gray.300"}
                      color="white"
                    >
                      VE
                    </Circle>
                  }
                />
                <IconButton
                  onClick={() => {
                    setWeekInput({
                      ...weekInput,
                      saturday: !weekInput.saturday,
                    });
                  }}
                  isRound
                  aria-label="Search database"
                  icon={
                    <Circle
                      size="40px"
                      bg={weekInput.saturday ? "brand.300" : "gray.300"}
                      color="white"
                    >
                      SA
                    </Circle>
                  }
                />
                <IconButton
                  onClick={() => {
                    setWeekInput({ ...weekInput, sunday: !weekInput.sunday });
                  }}
                  isRound
                  aria-label="Search database"
                  icon={
                    <Circle
                      size="40px"
                      bg={weekInput.sunday ? "brand.300" : "gray.300"}
                      color="white"
                    >
                      DO
                    </Circle>
                  }
                />
              </Stack>
              <Divider />
            </>
          )}

          <Text mt={6} mb={3} fontSize="xs">
            Che operazione vuoi fare?
          </Text>
          <Accordion defaultIndex={operation}>
            <AccordionItem>
              <h2>
                <AccordionButton
                  _expanded={{ bg: "green.500", color: "white" }}
                  onClick={() => {
                    setOperation(0);
                  }}
                >
                  <Box
                    as="span"
                    flex="1"
                    flexDirection={"column"}
                    textAlign="left"
                  >
                    <Text mt={2} fontSize="lg" fontWeight={"bold"}>
                      Apri risorsa
                    </Text>
                    {props.disableHours ? (
                      <Text mb={2} fontSize="md">
                        La risorsa risulterà aperta per tutto il periodo
                        selezionato
                      </Text>
                    ) : (
                      <Text mb={2} fontSize="md">
                        Scegli se aprire per tutta la giornata o impostare delle
                        fasce orarie d'apertura.
                      </Text>
                    )}
                  </Box>

                  <AccordionIcon />
                </AccordionButton>
              </h2>
              {!props.disableHours && (
                <AccordionPanel backgroundColor={"gray.100"} pb={4}>
                  <Box style={{ overflowY: "auto" }}>
                    <Box style={{ overflowY: "auto" }}>
                      {timesRange.map((_, index) => {
                        return (
                          <HStack mb={2} key={"timerangemonth_" + index}>
                            <ChevronRightIcon />
                            <Box>
                              <Input
                                style={{ width: 120 }}
                                value={timesRange[index][0]}
                                onChange={(ev) => {
                                  let times = [...timesRange];
                                  times[index][0] = ev.target.value;
                                  setTimesRange(times);
                                }}
                                type="time"
                                // min="09:00"
                                // max="18:00"
                              />
                            </Box>
                            <Text fontSize="xs" as="b">
                              -
                            </Text>
                            <Box>
                              <Input
                                //style={{ backgroundColor: "#ddd" }}
                                value={timesRange[index][1]}
                                onChange={(ev) => {
                                  let times = [...timesRange];
                                  times[index][1] = ev.target.value;
                                  setTimesRange(times);
                                }}
                                type="time"
                                // min="09:00"
                                // max="18:00"
                              ></Input>
                            </Box>
                            <IconButton
                              onClick={() => {
                                let times = [...timesRange];
                                times.splice(index, 1);
                                setTimesRange(times);
                              }}
                              isRound
                              size={"sm"}
                              variant="outline"
                              colorScheme="brand"
                              aria-label="Call Sage"
                              fontSize="16px"
                              icon={<DeleteIcon />}
                            />
                          </HStack>
                        );
                      })}
                    </Box>
                    <Button
                      colorScheme="brand"
                      onClick={() => {
                        let times = [...timesRange];
                        times.push(["08:00", "17:00"]);
                        setTimesRange(times);
                        console.log(times);
                        setOperation(0);
                        //setOperation(1);
                      }}
                      mt={4}
                      leftIcon={<SmallAddIcon />}
                      variant="link"
                    >
                      Aggiungi fascia oraria
                    </Button>
                    {timesRange.length < 1 ? (
                      <Text color={"brand"} p={1} py={2}>
                        Non hai impostato alcuna fascia oraria, salvando
                        risulterà aperto per tutto il giorno
                      </Text>
                    ) : null}
                    <Divider />
                    {/* <FormControl mx={1} mt={4} display="flex" alignItems="center">
                    <FormLabel htmlFor="email-alerts" mb="0">
                      Chiudi le giornate escluse in quest range?
                    </FormLabel>
                    <Switch colorScheme={"red"} id="email-alerts" />
                  </FormControl>
                  <Text color={"red"} p={1} py={2}>
                    Selezionando questa opzione i{" "}
                    {Object.keys(weekInput).map((daykey) => {
                      if (!(weekInput as any)[daykey])
                        return (
                          <>
                            <Badge colorScheme={"red"} mx={1}>
                              {(localWeekDays as any)[daykey]}
                            </Badge>
                          </>
                        );
                    })}
                    all'interno del periodo selezionato saranno impostati come
                    chiusi.
                  </Text> */}
                    {/* <Text color={"red"} p={1} py={2}>
                    ATTENZIONE, andrai a sovrascrivere eventuali fasce
                    da'pertura in questi giorni.
                  </Text> */}
                  </Box>
                </AccordionPanel>
              )}
            </AccordionItem>

            <AccordionItem>
              <h2>
                <AccordionButton
                  _expanded={{ bg: "pink.600", color: "white" }}
                  onClick={() => {
                    setOperation(1);
                  }}
                >
                  <Box
                    as="span"
                    flex="1"
                    flexDirection={"column"}
                    textAlign="left"
                  >
                    <Text mt={2} fontSize="lg" fontWeight={"bold"}>
                      Chiudi risorsa
                    </Text>
                    <Text mb={2} fontSize="md">
                      La risorsa risulterà chiusa per tutto il periodo
                      selezionato
                    </Text>
                  </Box>

                  <AccordionIcon />
                </AccordionButton>
              </h2>
              {/* <AccordionPanel pb={4}>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco
                  laboris nisi ut aliquip ex ea commodo consequat.
                </AccordionPanel> */}
            </AccordionItem>

            <AccordionItem>
              <h2>
                <AccordionButton
                  _expanded={{ bg: "gray.500", color: "white" }}
                  onClick={() => {
                    setOperation(2);
                  }}
                >
                  <Box
                    as="span"
                    flex="1"
                    flexDirection={"column"}
                    textAlign="left"
                  >
                    <Text mt={2} fontSize="lg" fontWeight={"bold"}>
                      Non so ancora se aprirò
                    </Text>
                    <Text mb={2} fontSize="md">
                      Seleziona questa opzione se non vuoi comunicare lo stato
                      della risorsa per il periodo selezionato
                    </Text>
                  </Box>

                  <AccordionIcon />
                </AccordionButton>
              </h2>
              {/* <AccordionPanel pb={4}>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco
                  laboris nisi ut aliquip ex ea commodo consequat.
                </AccordionPanel> */}
            </AccordionItem>
          </Accordion>
        </DrawerBody>

        <DrawerFooter>
          <Button
            onClick={() => {
              props.onClosed();
              onClose();
            }}
            mr={5}
          >
            Annulla
          </Button>
          <Button
            colorScheme={timesRange.length < 1 ? /*  "red" */ "brand" : "brand"}
            mr={3}
            onClick={() => {
              const range: any = {
                from: formatDate(dateRange.start),
                to: formatDate(dateRange.end),
                week: {},
              };

              if (props.disableHours) {
                delete range.week;

                if (operation === 0) {
                  range["isOpen"] = true;
                } else if (operation === 1) {
                  range["isOpen"] = false;
                } else if (operation === 2) {
                  range["isOpen"] = null;
                }
              } else {
                Object.entries(weekInput).forEach(([dayStr, value]) => {
                  if (!value) return;
                  const day = dayStr as keyof typeof weekInput;
                  if (operation === 0) {
                    const times = timesRange?.length
                      ? timesRange.map((time) => {
                          return `${time[0]}-${time[1]}`;
                        })
                      : ["00:00-24:00"];
                    range.week[day] = times;
                  } else if (operation === 1) {
                    range.week[day] = [];
                  } else if (operation === 2) {
                    range.week[day] = null;
                  } else {
                    return;
                  }
                });
              }

              console.log(range);

              props
                .addRange({
                  variables: { _ids: props._ids, range },
                })
                .then((res) => {
                  props.onFinish && props.onFinish();
                  props.refresh && props.refresh();
                });

              props.onClosed();
              onClose();
            }}
          >
            {timesRange.length < 1 ? /* "Chiudi periodo" */ "Salva" : "Salva"}
          </Button>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
};

export { AddRangeDrawer };
