import React, { useEffect, useState } from "react";

import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import {
  TRACK,
  TRACKS_CALENDAR_ADDRANGE,
  TRACK_ACCEPT_TERMS,
  TRACK_CALENDAR,
} from "stores/queries/tracks";
import { secure } from "stores/db/appstate";
import { CalendarDay, Resource } from "stores/queries/area";
import { TrackStatus } from "components/Track/TrackStatus";
import { OpeningsView } from "components/Operator/OpeningsView";

type TrackOperatorProps = {
  _id: string;
};

const TrackOperator = (props: TrackOperatorProps) => {
  const [permissions, setPermissions] = useState({
    calendar: false,
    manual: false,
  });

  const { loading, data } = useQuery<{ adminTrack: Resource }>(TRACK, {
    variables: { _id: props._id },
    context: { headers: { secureCode: `${secure()}` } },
    fetchPolicy: "no-cache",
    skip: !props._id || !secure(),
  });

  const [getCalendar, { loading: gcLoading }] = useLazyQuery<{
    adminTrackGetCalendarDays: CalendarDay[];
  }>(TRACK_CALENDAR, {
    context: { headers: { secureCode: `${secure()}` } },
    fetchPolicy: "network-only",
  });

  const getDays = async (data: {
    variables: {
      from: string;
      to: string;
      _id: string;
    };
  }) => {
    let days = null;

    try {
      const res = await getCalendar({
        variables: {
          from: data.variables.from,
          to: data.variables.to,
          _id: data.variables._id,
        },
      });
      days = res?.data?.adminTrackGetCalendarDays;
    } catch (error) {
      console.log(error);
    }

    return days;
  };

  const [addRange, { loading: aRLoading, error: arError, data: arData }] =
    useMutation(TRACKS_CALENDAR_ADDRANGE, {
      context: { headers: { secureCode: `${secure()}` } },
    });

  const [acceptTerms, { loading: atLoading, error: atError, data: atData }] =
    useMutation(TRACK_ACCEPT_TERMS, {
      refetchQueries: [TRACK],
      awaitRefetchQueries: true,
      context: { headers: { secureCode: `${secure()}` } },
    });

  useEffect(() => {
    if (data?.adminTrack) {
      setPermissions({
        calendar: data?.adminTrack?.secure?.permissions?.calendar,
        manual: data?.adminTrack?.secure?.permissions?.manual,
      });
    }
  }, [data?.adminTrack]);

  return (
    <OpeningsView
      _id={props._id}
      loading={loading || gcLoading || aRLoading}
      statusView={<TrackStatus _id={props._id} />}
      addRange={addRange}
      getDays={getDays}
      icon="search_trail"
      title={"TRACCIATO"}
      name={
        data?.adminTrack?.name?.find((n) => n.langIso === "it")?.value ||
        data?.adminTrack?.name[0]?.value ||
        ""
      }
      permissions={permissions}
      secureRefresh={
        data?.adminTrack?.secure?.lastRefresh
          ? new Date(data.adminTrack.secure.lastRefresh).getTime()
          : 0
      }
      termsAccepted={
        data?.adminTrack?.termsAndConditions?.accepted
          ? new Date(data.adminTrack.termsAndConditions.accepted).getTime()
          : 0
      }
      acceptTerms={acceptTerms}
    />
  );
};

export { TrackOperator };
