import { ChevronLeftIcon, ChevronRightIcon, EditIcon } from "@chakra-ui/icons";
import { Text, Flex, IconButton, Box, useToast } from "@chakra-ui/react";
import { formatDate } from "libs/helpers";
import { CalendarDay, Resource } from "stores/queries/area";
import theme from "theme/theme";
import moment, { Moment } from "moment";
import styled from "styled-components";
import { YearCalendar } from "components/YearCalendar/YearCalendar";
import { useEffect, useState } from "react";
import { AddRangeDrawer } from "components/Calendar/AddRangeDrawer";
import { Loading } from "ui/Loading/Loading";

const StyleWrapper = styled.div`
  table.year-calendar {
    border-collapse: collapse;
    width: 100%;
    border-top-width: 2px;
    border-bottom-width: 2px;
  }

  table.year-calendar thead {
    background-color: #fff;
    margin-bottom: 3px;
    border-bottom: 2px solid white;
  }

  table.year-calendar thead th {
    font-weight: lighter;
    padding: 10px 3px;
  }

  table.year-calendar thead th:first-child {
    padding-left: 33px !important;
  }

  table.year-calendar thead th.bolder {
    font-weight: bold;
  }

  table.year-calendar tbody {
    font-size: 0.8em;
  }

  table.year-calendar td {
    text-align: center;
    padding: 5px;
    cursor: pointer;
    border: 1px solid rgba(185, 185, 185, 0.15);
    background-color: ${theme.colors.gray[200]};
  }

  table.year-calendar tr:last-child td {
    border-bottom: none;
  }

  table.year-calendar tr td:last-child {
    border-right: none;
  }

  table.year-calendar td.month-name:first-child {
    border-left: none;
    border-width: 0px !important;
    background-color: #fff;
  }

  table.year-calendar td.month-name {
    font-weight: bold;
    text-align: left;
    cursor: default;
    border-left: none;
    border-right-width: 4px;
    background-color: #fff;
    width: 40px;
    text-transform: capitalize;
  }

  table.year-calendar th:first-child,
  table.year-calendar td:first-child {
    position: sticky;
    left: 0;
    background-color: #fff; /* Sfondo fisso per la colonna */
    z-index: 1; /* Assicurati che la prima colonna sia sempre visibile sopra le altre */
  }

  table.year-calendar td.prev-month,
  table.year-calendar td.next-month {
    color: transparent;
    cursor: default;
    pointer-events: none;
    background-color: #fff;
  }

  table.year-calendar td.week-separator {
    pointer-events: none;
    padding: 0;
    width: 8px;
    min-width: 0;
    background-color: #fff;
  }

  table.year-calendar td.week-separator {
    pointer-events: none;
    padding: 0;
    width: 8px;
    min-width: 0;
    background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAFCAYAAACNbyblAAAAAXNSR0IArs4c6QAAABZJREFUCB1jYEADmzdv/o8iRA0BoIEAKngPeSAlnXcAAAAASUVORK5CYII=");
  }

  table.year-calendar td.open {
    background-color: ${theme.colors.green[400]};
    color: white;
  }
  table.year-calendar td.closed {
    background-color: ${theme.colors.pink[400]};
    color: white;
  }

  table.year-calendar td.holidays {
    font-weight: bold;
    color: red;
    text-decoration: underline;
  }
  table.year-calendar td.weekend {
    font-style: italic;
    font-weight: bold;
  }

  table.year-calendar td.bolder {
    font-weight: bold;
  }

  table.year-calendar td.range,
  table.year-calendar td.selected {
    background-color: ${theme.colors.brand[700]};
    color: white;
  }

  table.year-calendar td.range-left {
    background-color: ${theme.colors.brand[400]};
  }

  table.year-calendar td.range-right {
    background-color: ${theme.colors.brand[400]};
  }

  div.calendar-controls {
    margin: 5px auto;
    display: table;
    font-size: 25px;
    line-height: 35px;
  }

  div.calendar-controls div {
    display: inline;
  }

  div.calendar-controls .current-year {
    margin: 0 30px;
  }

  div.calendar-controls .control {
    font-weight: bolder;
    color: #323232;
    font-size: 0.8em;
    cursor: pointer;
  }

  div.calendar-controls .today {
    position: absolute;
    right: 15px;
    line-height: 35px;
    font-size: 0.6em;
    text-transform: uppercase;
  }
`;

type OpeningYearCalendarProps = {
  addRange: any;
  _id: string;
  getDays: (data: {
    variables: {
      from: string;
      to: string;
      _id: string;
    };
  }) => Promise<CalendarDay[]>;
  calendarMeta: Resource["calendarMetadata"];
};

const OpeningYearCalendar = ({
  addRange,
  _id,
  getDays,
  calendarMeta,
}: OpeningYearCalendarProps) => {
  const [year, setYear] = useState<number>(moment().year());
  const [selectedDay, setSelectedDay] = useState<Moment>(moment());
  const [selectedRange, setSelectedRange] = useState<[Moment, Moment]>([
    moment(),
    moment(),
  ]);
  const [showDaysOfWeek, setShowDaysOfWeek] = useState<boolean>(true);
  const [forceFullWeeks, setForceFullWeeks] = useState<boolean>(false);
  const [showWeekSeparators, setShowWeekSeparators] = useState<boolean>(true);
  const [selectRange, setSelectRange] = useState<boolean>(true);
  const [customCSSclasses, setCustomCSSclasses] = useState<any>({});
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [calendarSelection, setCalendarSelection] = useState<{
    start: Date;
    end: Date;
  } | null>(null);

  const [loading, setLoading] = useState<boolean>(false);
  const toast = useToast();
  const successToast = (message = "Operazione completata") =>
    toast({
      title: message,
      status: "success",
      duration: 5000,
      isClosable: true,
    });

  const getYear = () => {
    if (year) {
      setLoading(true);
      const firstDayOfYear = new Date(year, 0, 1);
      const lastDayOfYear = new Date(year, 11, 31);

      getDays({
        variables: {
          from: formatDate(firstDayOfYear),
          to: formatDate(lastDayOfYear),
          _id,
        },
      })
        .then((res) => {
          let data = res;

          if (!data) {
            return null;
          }

          const open: string[] = [];
          const closed: string[] = [];

          for (let i = 0; i < data.length; i++) {
            const day = data[i].day;
            const schedules = data[i].schedules;

            if (schedules) {
              if (schedules.length) {
                open.push(day);
              } else {
                closed.push(day);
              }
            }
          }

          setCustomCSSclasses({
            open,
            closed,
          });
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    }
  };

  const onPrevYear = () => {
    setYear(year - 1);
  };

  const onNextYear = () => {
    setYear(year + 1);
  };

  const datePicked = (date: Moment) => {
    setSelectedDay(date);
    setSelectedRange([date, moment(date).add(15, "day")]);
  };

  const rangePicked = (start: Moment, end: Moment) => {
    setSelectedRange([start, end]);
    setSelectedDay(start);
    setCalendarSelection({ start: start.toDate(), end: end.toDate() });
    setDrawerOpen(true);
  };

  const toggleShowDaysOfWeek = () => {
    setShowDaysOfWeek(!showDaysOfWeek);
  };

  const toggleForceFullWeeks = () => {
    setShowDaysOfWeek(true);
    setForceFullWeeks(!forceFullWeeks);
  };

  const toggleShowWeekSeparators = () => {
    setShowWeekSeparators(!showWeekSeparators);
  };

  const toggleSelectRange = () => {
    setSelectRange(!selectRange);
  };

  useEffect(() => {
    getYear();
  }, [year]);

  return (
    <>
      <Flex
        maxHeight={"100%"}
        overflow={"hidden"}
        flex={1}
        margin={"0 auto"}
        /* maxWidth={"1000px"} */
        width={"100%"}
        borderRadius={30}
        backgroundColor={"#fff"}
        flexDirection={"column"}
        p={0}
      >
        <Flex p={5} py={4} pb={0} width={"100%"} flexDirection={"row"}>
          <IconButton
            onClick={() => {
              onPrevYear();
            }}
            isRound
            size={"sm"}
            variant="solid"
            aria-label="Call Sage"
            fontSize="20px"
            icon={<ChevronLeftIcon />}
          />
          <Flex flex={1} flexDirection={"column"}>
            <Text
              textTransform={"capitalize"}
              fontSize={16}
              colorScheme={"gray"}
              lineHeight={1.2}
              textAlign={"center"}
            >
              Apertura Annuale
            </Text>
            <Text
              textAlign={"center"}
              fontSize={16}
              fontWeight={"bold"}
              lineHeight={1.2}
              textTransform={"capitalize"}
            >
              {year}
            </Text>
          </Flex>
          <IconButton
            onClick={() => {
              onNextYear();
            }}
            isRound
            size={"sm"}
            variant="solid"
            aria-label="Call Sage"
            fontSize="20px"
            icon={<ChevronRightIcon />}
          />
        </Flex>

        <Box overflowY={"auto"} height={"100%"} p={0} pt={3} pb={8}>
          <StyleWrapper>
            <YearCalendar
              useIsoWeekday={false}
              titles={(day) => {
                return day.format("dddd");
              }}
              year={year}
              selectedDay={selectedDay}
              showDaysOfWeek={showDaysOfWeek}
              forceFullWeeks={forceFullWeeks}
              showWeekSeparators={showWeekSeparators}
              firstDayOfWeek={1}
              selectRange={selectRange}
              selectedRange={selectedRange}
              onPickDate={(date, classes) => datePicked(date)}
              onPickRange={(start, end) => rangePicked(start, end)}
              customClasses={customCSSclasses}
            />
          </StyleWrapper>
        </Box>
      </Flex>
      <AddRangeDrawer
        _ids={[_id]}
        addRange={addRange}
        refresh={getYear}
        open={drawerOpen}
        onClosed={() => {
          setDrawerOpen(false);
          setCalendarSelection(null);
          setSelectedRange([moment(), moment()]);
        }}
        onFinish={() => {
          successToast();
        }}
        calendarSelection={calendarSelection}
        disableHours
      />
      {loading ? <Loading /> : null}
    </>
  );
};

export { OpeningYearCalendar };
