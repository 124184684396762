import {
  Button,
  Flex,
  Heading,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Icon,
  Input,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";

import {
  Step,
  StepDescription,
  StepIcon,
  StepIndicator,
  StepNumber,
  StepSeparator,
  StepStatus,
  StepTitle,
  Stepper,
  useSteps,
  Text,
  HStack,
  VStack,
} from "@chakra-ui/react";
import { useGoogleLogin } from "@react-oauth/google";
import { FaCheckDouble, FaGoogle, FaHome, FaUser } from "react-icons/fa";
import axios from "axios";
import { CheckIcon } from "@chakra-ui/icons";
import { useMutation } from "@apollo/client";
import {
  POI_GET_GOOGLE_AT_OPERATOR,
  POI_OPERATOR,
  POI_UPDATE_GOOGLE_SYNC_OPERATOR,
} from "stores/queries/pois";
import { Loading } from "ui/Loading/Loading";
import { secure } from "stores/db/appstate";

const CLIENT_ID =
  "59082099396-vota4o6bld6gg8l0ni49jhv837rhj154.apps.googleusercontent.com";
/* const CLIENT_SECRET = "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"; */

type Account = {
  name: string;
  accountName: string;
  type: "PERSONAL" | "BUSINESS";
  verificationState: string;
  vettedState: string;
};

type Location = {
  name: string;
  title: string;
  regularHours: {
    periods: {
      openDay: string;
      openTime: {
        hours?: number;
        minutes?: number;
      };
      closeDay: string;
      closeTime: {
        hours?: number;
        minutes?: number;
      };
    }[];
  };
  specialHours: {
    specialHourPeriods: {
      startDate: {
        year: number;
        month: number;
        day: number;
      };
      endDate: {
        year: number;
        month: number;
        day: number;
      };
      closed?: boolean;
      openTime?: {
        hours?: number;
        minutes?: number;
      };
      closeTime?: {
        hours?: number;
        minutes?: number;
      };
    }[];
  };
  openInfo: {
    status: string;
    canReopen: boolean;
  };
  websiteUri: string;
  phoneNumbers: {
    primaryPhone: string;
  };
  storefrontAddress: {
    addressLines: string[];
    locality: string;
    postalCode: string;
    administrativeArea: string;
    regionCode: string;
  };
};

/* type TokenResponse = {
  access_token: string;
  refresh_token: string;
  [key: string]: any;
}; */

/* const retrieveAccessTokenAndRefreshToken = async (
  code: string
): Promise<TokenResponse> => {
  try {
    const response = await axios.post<TokenResponse>(
      https://www.googleapis.com/oauth2/v4/token",
      {
        code: code,
        client_id: CLIENT_ID,
        client_secret: CLIENT_SECRET,
        redirect_uri: "http://localhost:3000",
        grant_type: "authorization_code",
      }
    );
    const returnedData = response.data;
    console.log(returnedData);
    return returnedData;
  } catch (error) {
    console.error("Error retrieving access and refresh token:", error);
  }
}; */

// WARNING: THIS FUNCTION IS DISPLAYED FOR DEMONSTRATION PURPOSES ONLY. YOUR CLIENT_SECRET SHOULD NEVER BE EXPOSED ON THE CLIENT SIDE!!!!
/* const retrieveAccessTokenFromRefreshToken = async (
  refreshToken: string
): Promise<TokenResponse> => {
  try {
    const response = await axios.post<TokenResponse>(
      "https://www.googleapis.com/oauth2/v4/token",
      {
        refresh_token: refreshToken,
        client_id: CLIENT_ID,
        client_secret: CLIENT_SECRET,
        redirect_uri: "http://localhost:8000",
        grant_type: "refresh_token",
      }
    );
    const returnedData = response.data;
    console.log(returnedData);
    return returnedData;
  } catch (error) {
    console.error("Error retrieving access token from refresh token:", error);
  }
}; */

const retrieveGoogleMyBusinessAccounts = async (
  accessToken: string
): Promise<{ accounts: Account[] }> => {
  try {
    const response = await axios.get(
      "https://mybusinessaccountmanagement.googleapis.com/v1/accounts",
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    const returnedData = response.data;
    console.log(returnedData);
    return returnedData;
  } catch (error) {
    console.error("Error retrieving Google My Business accounts:", error);
  }
};

const retrieveGoogleMyBusinessLocations = async (
  account: string,
  accessToken: string
): Promise<{ locations: Location[] }> => {
  try {
    const response = await axios.get(
      `https://mybusinessbusinessinformation.googleapis.com/v1/${account}/locations`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        params: {
          // pageToken
          page_size: 100,
          pageSize: 100,
          read_mask:
            "name,title,regularHours,specialHours,moreHours,openInfo,storefrontAddress,websiteUri,phoneNumbers,categories,latlng",
        },

        //,serviceArea,labels,latlng,metadata,profile,storefrontAddress
      }
    );
    const returnedData = response.data;
    console.log(returnedData);
    return returnedData;
  } catch (error) {
    console.error("Error retrieving Google My Business locations:", error);
  }
};

const steps = [
  { title: "Login", description: "Accesso a Google" },
  { title: "Account", description: "Seleziona Account" },
  { title: "Struttura", description: "Seleziona Struttura" },
];

type GoogleSyncProps = {
  _id: string;
  visible: boolean;
  onClose: () => void;
};

const GoogleSync = (props: GoogleSyncProps) => {
  const { activeStep, goToNext, goToPrevious } = useSteps({
    index: 0,
    count: steps.length,
  });

  const [getAT, { loading: atLoading }] = useMutation(
    POI_GET_GOOGLE_AT_OPERATOR,
    { context: { headers: { secureCode: `${secure()}` } } }
  );

  const [syncGoogle, { loading: sgLoading }] = useMutation(
    POI_UPDATE_GOOGLE_SYNC_OPERATOR,
    {
      context: { headers: { secureCode: `${secure()}` } },
      refetchQueries: [POI_OPERATOR],
      awaitRefetchQueries: true,
    }
  );

  const isLastStep = activeStep === steps.length - 1;
  const hasCompletedAllSteps = activeStep === steps.length;
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [authCode, setAuthCode] = useState<string>();
  const [at, setAt] = useState<string>();

  const [googleInfo, setGoogleInfo] = useState<{
    account: string;
    location: string;
  }>({
    account: "",
    location: "",
  });

  const [accounts, setAccounts] = useState<Account[]>();
  const [locations, setLocations] = useState<Location[]>();

  const [locationInfo, setLocationInfo] = useState<string>();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (props.visible) {
      onOpen();
    } else {
      onClose();
    }
  }, [props.visible]);

  useEffect(() => {
    if (!isOpen) {
      props.onClose && props.onClose();
    }
  }, [isOpen]);

  const GoogleLogin = () => {
    const login = useGoogleLogin({
      scope: "https://www.googleapis.com/auth/business.manage",
      onSuccess: (codeResponse) => {
        setAuthCode(codeResponse.code);
        console.log(codeResponse);
        setLoading(true);

        getAT({
          variables: {
            id: props._id,
            code: codeResponse.code,
            redirectUrl: window.location.origin,
          },
        }).then((at) => {
          if (at?.data?.operatorPoiGetGoogleSyncAccessToken) {
            console.log(at.data.operatorPoiGetGoogleSyncAccessToken);
            setAt(at.data.operatorPoiGetGoogleSyncAccessToken);
            retrieveGoogleMyBusinessAccounts(
              at.data.operatorPoiGetGoogleSyncAccessToken
            ).then((ac) => {
              console.log("ACCOUNTS:", ac);
              setAccounts(ac.accounts);
              goToNext();
              setLoading(false);
            });
          } else {
            console.error("GOOGLE SYNC - Error: no AT present", at?.data);
            setLoading(false);
          }
        });
      },
      onError: (error) => {
        console.log("GOOGLE SYNC - Login Failed:", error);
      },
      flow: "auth-code",
    });

    return (
      <Flex
        justifyContent={"center"}
        alignItems={"center"}
        w={"full"}
        h={200}
        p={2}
        flexDirection={"column"}
      >
        <Text>
          Accedi con il tuo account Google e seleziona la spunta:{" "}
          <b>
            Visualizzare, modificare, creare ed eliminare le schede di attività
            commerciali su Google
          </b>
        </Text>
        <Button
          mt={6}
          onClick={() => login()}
          color={"dark"}
          colorScheme={"dark"}
          variant="outline"
          size="lg"
          leftIcon={<FaGoogle />}
        >
          Login con Google
        </Button>
      </Flex>
    );
  };

  const GoogleAccount = () => {
    return (
      <>
        <Text
          fontWeight={"bold"}
          w={"full"}
          textAlign={"center"}
          mt={4}
          fontSize={"xl"}
        >
          Seleziona Account
        </Text>
        <Flex
          justifyContent={"flex-start"}
          alignItems={"center"}
          flexDirection={"column"}
          w={"full"}
          h={200}
          p={2}
          pt={2}
          overflow={"auto"}
        >
          {accounts?.map((account, index) => {
            return (
              <HStack
                key={"account_" + index}
                _hover={{ cursor: "pointer", bg: "gray.200" }}
                bg={
                  googleInfo.account === account.name ? "gray.200" : "gray.100"
                }
                padding={4}
                w={"full"}
                mt={1}
                mb={1}
                borderRadius={8}
                onClick={() => {
                  setGoogleInfo({
                    ...googleInfo,
                    account: account.name,
                  });
                  setLoading(true);
                  retrieveGoogleMyBusinessLocations(account.name, at).then(
                    (res) => {
                      setLocations(res?.locations || null);
                      setLoading(false);
                      goToNext();
                    }
                  );
                }}
              >
                <FaGoogle size={40} />
                <VStack pl={2} flex={1} alignItems={"flex-start"}>
                  <Text fontWeight={"bold"} lineHeight={1} fontSize={"lg"}>
                    {account.accountName}
                  </Text>
                  <Text lineHeight={1} fontSize={"sm"} color={"gray.500"}>
                    {account.type === "BUSINESS"
                      ? "ACCOUNT AZIENDALE"
                      : "ACCOUNT PERSONALE"}
                  </Text>
                </VStack>
              </HStack>
            );
          })}
        </Flex>
      </>
    );
  };

  const GoogleStructure = () => {
    return (
      <>
        <Text
          fontWeight={"bold"}
          w={"full"}
          textAlign={"center"}
          mt={4}
          fontSize={"xl"}
        >
          Seleziona Struttura
        </Text>
        <Flex
          justifyContent={"flex-start"}
          alignItems={"center"}
          flexDirection={"column"}
          w={"full"}
          h={200}
          p={2}
          pt={2}
          overflow={"auto"}
        >
          {locations?.map((location, index) => {
            return (
              <HStack
                key={"account_" + index}
                _hover={{ cursor: "pointer", bg: "gray.200" }}
                bg={
                  googleInfo.account === location.name ? "gray.200" : "gray.100"
                }
                padding={4}
                w={"full"}
                mt={1}
                mb={1}
                borderRadius={8}
                onClick={() => {
                  setGoogleInfo({
                    ...googleInfo,
                    location: location.name,
                  });
                  syncGoogle({
                    variables: {
                      id: props._id,
                      googleSyncAccount: {
                        googleAccountId: googleInfo.account,
                        locationId: location.name,
                      },
                    },
                  });
                  goToNext();
                }}
              >
                <FaHome size={40} />
                <VStack pl={2} flex={1} alignItems={"flex-start"}>
                  <Text fontWeight={"bold"} lineHeight={1} fontSize={"lg"}>
                    {location.title}
                  </Text>
                  {location.storefrontAddress && (
                    <Text lineHeight={1} fontSize={"sm"} color={"gray.500"}>
                      {location.storefrontAddress.addressLines.join(", ")} -{" "}
                      {location.storefrontAddress.locality}
                    </Text>
                  )}
                </VStack>
              </HStack>
            );
          })}
        </Flex>
      </>
    );
  };

  const renderStep = () => {
    switch (activeStep) {
      case 0:
        return <GoogleLogin />;
      case 1:
        return <GoogleAccount />;
      case 2:
        return <GoogleStructure />;
    }
  };

  return (
    <Modal size={"xl"} isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Sincronizza verso Google</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Flex flexDir="column" width="100%">
            <Stepper colorScheme={"brand"} index={activeStep}>
              {steps.map((step, index) => (
                <Step key={index}>
                  <StepIndicator>
                    <StepStatus
                      complete={<StepIcon />}
                      incomplete={<StepNumber />}
                      active={<StepNumber />}
                    />
                  </StepIndicator>

                  <StepSeparator />
                </Step>
              ))}
            </Stepper>

            {renderStep()}

            {hasCompletedAllSteps && (
              <>
                <Text
                  fontWeight={"bold"}
                  w={"full"}
                  textAlign={"center"}
                  mt={4}
                  fontSize={"xl"}
                >
                  Sincronizzazione attivata!
                </Text>
                <Flex
                  flexDirection={"column"}
                  h={200}
                  justifyItems={"center"}
                  alignItems={"center"}
                >
                  {/* <CheckIcon m={4} fontSize={"6xl"} color="brand.500" /> */}
                  {/*  <Text style={{ maxWidth: 500, fontSize: 13 }} size={"xs"}>
                    {JSON.stringify(googleInfo)}
                  </Text> */}
                  <Button size={"lg"} colorScheme={"brand"} onClick={onClose}>
                    Torna al calendario
                  </Button>
                </Flex>
              </>
            )}
            {/* <Flex width="100%" justify="flex-end" gap={4}>
              {hasCompletedAllSteps ? (
                <Button size="sm" onClick={onClose}>
                  CHIUDI!
                </Button>
              ) : (
                <>
                  <Button
                    isDisabled={activeStep === 0}
                    onClick={goToPrevious}
                    variant="ghost"
                  >
                    Indietro
                  </Button>
                  <Button colorScheme={"brand"} onClick={goToNext}>
                    {isLastStep ? "Fine!" : "Avanti"}
                  </Button>
                </>
              )}
            </Flex> */}
          </Flex>
          {loading || atLoading || sgLoading ? <Loading /> : false}
        </ModalBody>

        {/*      <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={onClose}>
            Close
          </Button>
          <Button variant="ghost">Secondary Action</Button>
        </ModalFooter> */}
      </ModalContent>
    </Modal>
  );
};

export { GoogleSync };
