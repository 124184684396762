import {
  Flex,
  Text,
  Divider,
  Image,
  VStack,
  Box,
  HStack,
  IconButton,
  Tooltip,
  Spinner,
} from "@chakra-ui/react";
import { ENV } from "config";
import { NavLink } from "react-router-dom";

import LogoColor from "../../../assets/img/logo-bianco.png";
import { SideBarLink } from "./Links";
import { checkScope } from "libs/apollo/auth";
import { APTSelector } from "./APTSelector";
import React from "react";
import { ACTUAL_AUTH } from "stores/db/auth";
import { ETgpArea } from "stores/queries/area";

const SidebarBrand = () => {
  const [area, setArea] = React.useState<ETgpArea | undefined>(
    ACTUAL_AUTH.area as ETgpArea
  );

  const areaName = React.useMemo(
    () =>
      Object.keys(ETgpArea).find(
        (key) => (ETgpArea as any)[key] === ACTUAL_AUTH.area
      ),
    [ACTUAL_AUTH.area]
  );
  return (
    <>
      <Flex align="center" direction="column" bg="tgpBlue" pt={5}>
        <NavLink to="/areas">
          <Flex w="100%" align="center" mb="12px" direction={"column"}>
            <Image src={LogoColor} width={180} mb={2} />
            <Text
              fontSize="17px"
              opacity={0.9}
              fontWeight="medium"
              color="white"
            >
              {ENV.deviceInfo.app.name}
            </Text>
          </Flex>
        </NavLink>
        <Divider />
      </Flex>
      {checkScope("root") ? (
        <Box pb={3} bg="gray.100" borderBottomWidth={2}>
          <VStack
            align="start"
            bg="gray.100"
            borderBottomWidth={0}
            pb={0}
            pt={5}
          >
            <HStack
              w="100%"
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <SideBarLink
                key={"sidebarlink_root_1"}
                route={{
                  name: "Tutte le aree",
                  path: "/overview",
                  icon: "menu",
                }}
              />
            </HStack>

            <SideBarLink
              key={"sidebarlink_root_2"}
              route={{
                name: "Gestione utenti",
                path: "/users",
                icon: "group_outing_fullcolor",
              }}
            />
          </VStack>
          <Box mt={2}>
            <APTSelector area={area} setArea={setArea} />
          </Box>
        </Box>
      ) : (
        <VStack p={5} align="start" bg="gray.100" borderBottomWidth={2}>
          <Text fontSize={"lg"} color={"tgpBlue"} fontWeight={"bold"}>
            A.P.T. {areaName}
          </Text>
        </VStack>
      )}
    </>
  );
};

export default SidebarBrand;
