import React, { useState, useEffect } from "react";
import moment, { Moment } from "moment";
import Day from "./Day";
import { range, getMonthStr } from "./utils";

interface MonthProps {
  year: number;
  month: number;
  forceFullWeeks: boolean;
  showWeekSeparators: boolean;
  selectedDay: Moment;
  firstDayOfWeek: number;
  selectingRange?: Moment[];
  selectRange: boolean;
  selectedRange?: Moment[];
  customClasses?: Record<
    string,
    | string
    | string[]
    | ((day: Moment) => boolean)
    | { start: string; end: string }
  >;
  titles?: (day: Moment) => string | undefined;
  dayClicked: (day: Moment, classes: string) => void;
  dayHovered: (day: Moment) => void;
}

const Month: React.FC<MonthProps> = ({
  year,
  month,
  forceFullWeeks,
  showWeekSeparators,
  selectedDay,
  firstDayOfWeek,
  selectingRange,
  selectRange,
  selectedRange,
  customClasses,
  titles,
  dayClicked,
  dayHovered,
}) => {
  const [selectingRangeStart, setSelectingRangeStart] = useState<
    number | undefined
  >();
  const [selectingRangeEnd, setSelectingRangeEnd] = useState<
    number | undefined
  >();

  useEffect(() => {
    if (selectingRange !== undefined) {
      setSelectingRangeStart(selectingRange[0].month());
      setSelectingRangeEnd(selectingRange[1].month());
    }
  }, [selectingRange]);

  const renderMonthDays = () => {
    const monthStart = moment([year, month, 1]);
    let prevMonthDaysCount = monthStart.weekday();
    while (prevMonthDaysCount < firstDayOfWeek) {
      prevMonthDaysCount += 7;
    }
    const numberOfDays = monthStart.daysInMonth();
    const totalDays = forceFullWeeks ? 42 : 37;

    const days: JSX.Element[] = [];
    range(firstDayOfWeek + 1, totalDays + firstDayOfWeek + 1).forEach((i) => {
      const day = moment([year, month, i - prevMonthDaysCount]);
      const classes: string[] = [];
      const title = titles?.(day);
      if (i <= prevMonthDaysCount) {
        classes.push("prev-month");
      } else if (i > numberOfDays + prevMonthDaysCount) {
        classes.push("next-month");
      } else {
        if (selectRange) {
          let start = (selectingRange || selectedRange)![0];
          let end = (selectingRange || selectedRange)![1];
          if (end.isBefore(start)) {
            [end, start] = selectingRange || selectedRange!;
          }
          if (day.isBetween(start, end, "day", "[]")) {
            classes.push("range");
          }
          if (day.isSame(start, "day")) {
            classes.push("range-left");
          }
          if (day.isSame(end, "day")) {
            classes.push("range-right");
          }
        } else if (day.isSame(selectedDay, "day")) {
          classes.push("selected");
        }
        if (customClasses instanceof Function) {
          classes.push(customClasses(day));
        }
      }
      if ((i - 1) % 7 === 0) {
        classes.push("bolder");
      }
      if (customClasses) {
        Object.keys(customClasses).forEach((k) => {
          const obj = customClasses[k];
          if (typeof obj === "string") {
            if (obj.indexOf(day.format("ddd")) > -1) {
              classes.push(k);
            }
          } else if (obj instanceof Array) {
            obj.forEach((d) => {
              if (day.format("YYYY-MM-DD") === d) classes.push(k);
            });
          } else if (obj instanceof Function) {
            if (obj(day)) {
              classes.push(k);
            }
          } else if (obj.start && obj.end) {
            const startDate = moment(obj.start, "YYYY-MM-DD").add(-1, "days");
            const endDate = moment(obj.end, "YYYY-MM-DD").add(1, "days");
            if (day.isBetween(startDate, endDate)) {
              classes.push(k);
            }
          }
        });
      }
      if (showWeekSeparators) {
        if ((i - 1) % 7 === firstDayOfWeek && days.length) {
          days.push(<td className="week-separator" key={`seperator-${i}`} />);
        }
      }
      days.push(
        <Day
          key={`day-${i}`}
          day={day.isValid() ? day : null}
          classes={classes.join(" ")}
          dayClicked={(d) => dayClicked(d, classes.join(" "))}
          dayHovered={(d) => dayHovered(d)}
          title={title}
        />
      );
    });

    return days;
  };

  return (
    <tr>
      <td className="month-name">{getMonthStr(year, month)}</td>
      {renderMonthDays()}
    </tr>
  );
};

export default Month;
