import React, { useState } from "react";
import moment from "moment";
import Month from "./Month";
import { range } from "./utils";

interface YearCalendarProps {
  year: number;
  forceFullWeeks: boolean;
  showDaysOfWeek: boolean;
  showWeekSeparators: boolean;
  firstDayOfWeek: number;
  useIsoWeekday: boolean;
  selectedRange?: moment.Moment[];
  selectRange: boolean;
  onPickDate: (date: moment.Moment, classes: any) => void;
  onPickRange: (startDate: moment.Moment, endDate: moment.Moment) => void;
  selectedDay: any;
  customClasses: any;
  titles: (date: moment.Moment) => string;
}

const defaultProps: YearCalendarProps = {
  forceFullWeeks: false,
  showDaysOfWeek: true,
  showWeekSeparators: true,
  firstDayOfWeek: 1,
  useIsoWeekday: false,
  selectRange: false,
  selectedDay: moment(),
  customClasses: null,
  titles: null,
  year: moment().year(),
  onPickDate: () => {},
  onPickRange: () => {},
};

const YearCalendar = (props: YearCalendarProps = defaultProps) => {
  const [selectingRange, setSelectingRange] = useState<
    moment.Moment[] | undefined
  >(undefined);

  const dayClicked = (date: moment.Moment, classes: any) => {
    if (!date) {
      return;
    }

    const { selectRange, onPickRange, onPickDate } = props;

    if (!selectRange) {
      if (onPickDate) {
        onPickDate(date, classes);
      }
      return;
    }

    if (!selectingRange) {
      setSelectingRange([date, date]);
    } else {
      if (onPickRange) {
        const [startDate, endDate] = selectingRange;
        onPickRange(
          startDate > date ? date : startDate,
          startDate > date ? startDate : date
        );
      }
      setSelectingRange(undefined);
    }
  };

  const dayHovered = (hoveredDay: moment.Moment) => {
    if (!hoveredDay) {
      return;
    }

    if (selectingRange) {
      selectingRange[1] = hoveredDay;
      setSelectingRange([...selectingRange]);
    }
  };

  const renderDaysOfWeek = () => {
    const {
      useIsoWeekday,
      firstDayOfWeek = 0,
      forceFullWeeks = false,
      showWeekSeparators = true,
    } = props;
    const totalDays = forceFullWeeks ? 42 : 37;

    const days: any = [];
    range(firstDayOfWeek, totalDays + firstDayOfWeek).forEach((i) => {
      const today = new Date();
      // Se vogliamo usare ISO weekday, in cui la settimana inizia da lunedì
      const weekdayOffset = useIsoWeekday ? 1 : 0;

      // Imposta la data aggiungendo 'i' giorni
      const targetDate = new Date(today);
      targetDate.setDate(today.getDate() - today.getDay() + i + weekdayOffset);
      const dayAbbreviation = targetDate.toLocaleDateString("it-IT", {
        weekday: "short",
      });

      if (showWeekSeparators) {
        if (i % 7 === firstDayOfWeek && days.length) {
          days.push(<th className="week-separator" key={`seperator-${i}`} />);
        }
      }
      days.push(
        <th key={`weekday-${i}`} className={i % 7 === 0 ? "bolder" : ""}>
          {dayAbbreviation.charAt(0).toUpperCase()}
        </th>
      );
    });

    return (
      <tr>
        <th>&nbsp;</th>
        {days}
      </tr>
    );
  };

  /*   const { selectingRange: currentSelectingRange } = selectingRange; */

  //const currentSelectingRange = { ...selectingRange };

  const months = range(0, 12).map((month) => (
    <Month
      month={month}
      key={`month-${month}`}
      dayClicked={(d, classes) => dayClicked(d, classes)}
      dayHovered={(d) => dayHovered(d)}
      {...props}
      selectingRange={selectingRange}
    />
  ));

  return (
    <table className="year-calendar">
      <thead className="day-headers">
        {props.showDaysOfWeek ? renderDaysOfWeek() : null}
      </thead>
      <tbody>{months}</tbody>
    </table>
  );
};

/* YearCalendar.defaultProps = defaultProps; */

export { YearCalendar };
