import React from "react";

import { useMutation, useQuery } from "@apollo/client";
import {
  ACCOMODATION,
  ACCOMODATIONS_GET_QR_EMAIL,
  ACCOMODATIONS_GET_QR_PDF,
  ACCOMODATIONS_SECURE_MANAGE,
} from "stores/queries/accomodations";

import { Resource } from "stores/queries/area";
import { OperatorLink } from "components/Operator/OperatorLink";

interface AccomodationOperatorLinkProps {
  _id: string;
}

const AccomodationOperatorLink = (props: AccomodationOperatorLinkProps) => {
  const { loading, data } = useQuery<{ adminAccomodation: Resource }>(
    ACCOMODATION,
    {
      variables: { _id: props._id },
    }
  );

  const [secureManage, { loading: smLoading }] = useMutation(
    ACCOMODATIONS_SECURE_MANAGE,
    {
      refetchQueries: [{ query: ACCOMODATION, variables: { _id: props._id } }],
    }
  );

  const [getQrPdf, { loading: gqpLoading }] = useMutation(
    ACCOMODATIONS_GET_QR_PDF
  );
  const [getQrEmail, { loading: gqeLoading }] = useMutation(
    ACCOMODATIONS_GET_QR_EMAIL
  );

  return (
    <OperatorLink
      _id={props._id}
      type={"accomodation"}
      loading={loading /* || smLoading || gqpLoading || gqeLoading */}
      secureManage={secureManage}
      resource={data?.adminAccomodation}
      getQrPdf={getQrPdf}
      getQrEmail={getQrEmail}
    />
  );
};

export { AccomodationOperatorLink };
