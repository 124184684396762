import React from "react";
import { TrackOperator } from "components/Track/TrackOperator";
import { Box, Text } from "@chakra-ui/react";
import { secure } from "stores/db/appstate";
import { PoiOperator } from "components/Poi/PoiOperator";
import { TransportOperator } from "components/Transport/TransportOperator";
import { AccomodationOperator } from "components/Accomodation/AccomodationOperator";

const Openings = () => {
  let urlParams = new URLSearchParams(window.location.search);
  let _id = urlParams.get("i");
  let type = urlParams.get("t");

  if (!_id || !type || !secure())
    return (
      <>
        <Text>Error</Text>
      </>
    );

  switch (type) {
    case "track":
      return <TrackOperator _id={_id} />;
    case "poi":
      return <PoiOperator _id={_id} />;
    case "transport":
      return <TransportOperator _id={_id} />;
    case "accomodation":
      return <AccomodationOperator _id={_id} />;
    default:
      <Box>
        <Text size={"lg"}>Error</Text>
      </Box>;
      break;
  }
};

export { Openings };
