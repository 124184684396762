import { graphql } from "libs/apollo/__generated__";

const ACCOMODATION_FRAGMENT = graphql(`
  fragment accomodationFragment on Accomodation {
    _id
    name {
      langIso
      value
    }
    externalInfo {
      tgpId
      externalId
      externalSource
      updateLink
    }
    calendar {
      timeEncoding {
        times
      }
    }
    accomodationMetadata {
      rooms
      beds
      stars
    }
    calendarMetadata {
      lastDay
      isCalendarUpdated
      lastUpdate
      updatedBy
      lastOperatorCheck
      threeMonthAlertSent
      threeMonthAlertDate
      emptyCalendarAlertSent
      emptyCalendarAlertDate
    }
    calendarSync {
      googleRefreshToken
      googleAccountId
      googleLocationId
      googleFirstSync
      googleLastSync
      googleLastSyncResult
      googleLastSyncError
      googleLastSyncErrorDate
      googleSyncInError
    }
    geoLocation {
      coordinatesMultiLineString
      coordinatesLineString
      coordinatesPoint
    }
    filters
    owner
    area
    secure {
      code
      lastRefresh
      permissions {
        manual
        calendar
      }
    }
    eventsCount
    email
    emailFromTgp
    emailSent
    termsAndConditions {
      accepted
      version
      newVersion
    }
  }
`);

const ACCOMODATION = graphql(`
  query adminAccomodation($_id: ID!) {
    adminAccomodation(_id: $_id) {
      ...accomodationFragment
    }
  }
`);

const ACCOMODATION_OPERATOR = graphql(`
  query operatorAccomodation($id: ID!) {
    operatorAccomodation(_id: $id) {
      ...accomodationFragment
    }
  }
`);

const ACCOMODATIONS_SECURE_MANAGE = graphql(`
  mutation adminAccomodationsSecureManage(
    $_ids: [ID!]!
    $secure: SecureInput!
  ) {
    adminAccomodationsSecureManage(_ids: $_ids, secure: $secure) {
      _id
      secure {
        code
        permissions {
          manual
          calendar
        }
      }
    }
  }
`);

const ACCOMODATIONS = graphql(`
  query adminAccomodations {
    adminAccomodations {
      ...accomodationFragment
    }
  }
`);

const ACCOMODATIONS_MIN = graphql(`
  query adminAccomodationsMin {
    adminAccomodations {
      ...accomodationFragment
    }
  }
`);

const ACCOMODATION_DAYS = graphql(`
  query adminAccomodationGetDays($id: ID!, $from: String!, $to: String!) {
    adminAccomodationGetDays(_id: $id, from: $from, to: $to)
  }
`);

const ACCOMODATIONS_DAYS = graphql(`
  query adminAccomodationsGetDays($from: String!, $to: String!) {
    adminAccomodationsGetDays(from: $from, to: $to) {
      _id
      name {
        langIso
        value
      }
      rooms
      beds
      stars
      days
    }
  }
`);

const ACCOMODATIONS_CALENDAR_ADDRANGE = graphql(`
  mutation adminAccomodationCalendarAddRange(
    $_ids: [ID!]!
    $range: CalendarDayRangeInput
  ) {
    adminAccomodationCalendarAddRange(_ids: $_ids, range: $range) {
      _id
    }
  }
`);

const ACCOMODATIONS_GET_QR_PDF = graphql(`
  mutation AdminAccomodationGetQrCodePdf($_id: ID!) {
    adminAccomodationGetQrCodePdf(_id: $_id)
  }
`);

const ACCOMODATIONS_GET_QR_EMAIL = graphql(`
  mutation AdminAccomodationsSendEmail($_ids: [ID!]!) {
    adminAccomodationsSendEmail(_ids: $_ids) {
      _id
      success
      errors
    }
  }
`);

const ACCOMODATION_ACCEPT_TERMS = graphql(`
  mutation AdminAccomodationAcceptTermsAndConditions($id: ID!) {
    adminAccomodationAcceptTermsAndConditions(_id: $id) {
      _id
    }
  }
`);

const ACCOMODATION_UPDATE = graphql(`
  mutation AdminAccomodationUpdate(
    $id: ID!
    $accomodation: AccomodationInput!
  ) {
    adminAccomodationUpdate(_id: $id, accomodation: $accomodation) {
      _id
    }
  }
`);

const ACCOMODATION_CALENDAR_RESET = graphql(`
  mutation adminAccomodationCalendarReset($_id: ID!) {
    adminAccomodationCalendarReset(_id: $_id) {
      _id
    }
  }
`);

export const ACCOMODATION_CALENDAR_RESET_OPERATOR = graphql(`
  mutation operatorAccomodationCalendarReset($_id: ID!) {
    operatorAccomodationCalendarReset(_id: $_id) {
      _id
    }
  }
`);

const ACCOMODATIONS_GET_STATS = graphql(`
  query AdminAccomodationGetStatistics {
    adminAccomodationGetStatistics {
      area
      statistics {
        total
        totalOpen
        totalClosed
        totalNd
        totalEditedByAdmin
        totalEditedByOperator
        totalNotEdited
        totalConditionsAccepted
      }
      emailStatistics {
        emptyCalendarAlertsLast30daysSent
        threeMonthAlerts30daysSent
      }
    }
  }
`);

const ACCOMODATIONS_GET_ALLAREAS_STATS = graphql(`
  query AccomodationGetAllAreasStatistics {
    accomodationGetAllAreasStatistics {
      area
      statistics {
        total
        totalOpen
        totalClosed
        totalNd
        totalEditedByAdmin
        totalEditedByOperator
        totalNotEdited
        totalConditionsAccepted
      }
      emailStatistics {
        emptyCalendarAlertsLast30daysSent
        threeMonthAlerts30daysSent
      }
    }
  }
`);

const ACCOMODATIONS_MIN_RO = graphql(`
  query RoAccomodationsByArea($area: String!) {
    roAccomodationsByArea(area: $area) {
      _id
      name {
        langIso
        value
      }
      calendar {
        timeEncoding {
          times
        }
      }
      accomodationMetadata {
        rooms
        beds
        stars
      }
      eventsCount
      filters
      email
      owner
      area
      emailSent
    }
  }
`);

const ACCOMODATIONS_DAYS_RO = graphql(`
  query RoAccomodationsGetDays($area: String!, $from: String!, $to: String!) {
    roAccomodationsGetDays(area: $area, from: $from, to: $to) {
      _id
      name {
        langIso
        value
      }
      rooms
      beds
      stars
      days
    }
  }
`);

/* const ACCOMODATION_GET_GOOGLE_AT_OPERATOR = graphql(`
  mutation OperatorAccomodationGetGoogleSyncAccessToken(
    $id: ID!
    $code: String!
  ) {
    operatorAccomodationGetGoogleSyncAccessToken(_id: $id, code: $code)
  }
`);

const ACCOMODATION_UPDATE_GOOGLE_SYNC_OPERATOR = graphql(`
  mutation OperatorAccomodationSaveGoogleSyncAccount(
    $id: ID!
    $googleSyncAccount: GoogleSyncAccountInput!
  ) {
    operatorAccomodationSaveGoogleSyncAccount(
      _id: $id
      googleSyncAccount: $googleSyncAccount
    ) {
      _id
    }
  }
`);

const ACCOMODATION_CANCEL_GOOGLE_SYNC_OPERATOR = graphql(`
  mutation OperatorAccomodationCancelGoogleSync($id: ID!) {
    operatorAccomodationCancelGoogleSync(_id: $id) {
      _id
    }
  }
`); */

export {
  ACCOMODATION_FRAGMENT,
  ACCOMODATION,
  ACCOMODATION_OPERATOR,
  ACCOMODATIONS,
  ACCOMODATIONS_MIN,
  ACCOMODATION_DAYS,
  ACCOMODATIONS_DAYS,
  ACCOMODATIONS_CALENDAR_ADDRANGE,
  ACCOMODATION_CALENDAR_RESET,
  ACCOMODATION_UPDATE,
  ACCOMODATIONS_GET_QR_PDF,
  ACCOMODATIONS_GET_QR_EMAIL,
  ACCOMODATION_ACCEPT_TERMS,
  ACCOMODATIONS_SECURE_MANAGE,
  ACCOMODATIONS_MIN_RO,
  ACCOMODATIONS_DAYS_RO,

  /*
  ,

  ACCOMODATION_GET_GOOGLE_AT_OPERATOR,
  ACCOMODATION_UPDATE_GOOGLE_SYNC_OPERATOR,
  ACCOMODATION_CANCEL_GOOGLE_SYNC_OPERATOR,
  ACCOMODATIONS_AREA_MIN_RO, */
  ACCOMODATIONS_GET_ALLAREAS_STATS,
  ACCOMODATIONS_GET_STATS,
};
