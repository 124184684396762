import React from "react";
import { MowiIcons } from "ui/MowiIcon/Icon";

interface NavRoute {
  name: string;
  path: string;
  icon: MowiIcons;
}

const routes: NavRoute[] = [
  {
    name: "Panoramica",
    path: "/dashboard",
    icon: "grid_view",
  },
  // {
  //   name: "Piste e tracciati",
  //   path: "/tracks",
  //   icon: "search_trail",
  // },
  {
    name: "Punti d'interesse",
    path: "/pois",
    icon: "locator",
  },
  {
    name: "Strutture Ricettive",
    path: "/accomodations",
    icon: "my_hotel",
  },
  {
    name: "Info Points",
    path: "/infos",
    icon: "info_point",
  },
  // {
  //   name: "Impianti di risalita",
  //   path: "/transports",
  //   icon: "aerialway_gondola",
  // },
];

export { routes };
export type { NavRoute };
