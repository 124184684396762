import React from "react";

import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import {
  POI_CALENDAR,
  POIS_CALENDAR_ADDRANGE,
  POI_CALENDAR_RESET,
  POI,
  POI_FRAGMENT,
} from "stores/queries/pois";
import { CalendarDay, Resource } from "stores/queries/area";
import { OpeningCalendar } from "components/Calendar/OpeningCalendar";
import { secureInfos } from "stores/db/appstate";
import { Button, Circle, Flex, Link, Text, VStack } from "@chakra-ui/react";
import { MowiIcon } from "ui/MowiIcon/Icon";
import { ArrowBackIcon, CalendarIcon } from "@chakra-ui/icons";
import Card from "ui/Card";

interface PoiCalendarInfosProps {
  _id: string;
  area: string;
}

const PoiCalendarInfos = (props: PoiCalendarInfosProps) => {
  const [getCalendar, { loading }] = useLazyQuery<{
    adminPoiGetCalendarDays: CalendarDay[];
  }>(POI_CALENDAR, {
    context: { headers: { areaSecureCode: `${secureInfos()}` } },
    fetchPolicy: "network-only",
  });

  const { data: poiData, loading: poiLoading } = useQuery(POI, {
    fetchPolicy: "network-only",
    context: { headers: { areaSecureCode: `${secureInfos()}` } },
    variables: {
      _id: props._id,
    },
  });

  const getDays = async (data: {
    variables: {
      from: string;
      to: string;
      _id: string;
    };
  }) => {
    let days = null;

    try {
      const res = await getCalendar({
        variables: {
          from: data.variables.from,
          to: data.variables.to,
          _id: data.variables._id,
        },
      });
      days = res?.data?.adminPoiGetCalendarDays;
    } catch (error) {
      console.log(error);
    }

    return days;
  };
  return (
    <VStack
      flex={1}
      style={{
        padding: 20,
        alignItems: "center",
        width: "100%",
      }}
    >
      <Flex
        flexDirection={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
        width={"100%"}
        p={4}
        py={6}
      >
        <Flex flexDirection={"row"} alignItems={"center"}>
          <Link href={`?si=${secureInfos()}&a=${props.area}`}>
            <Circle bgGradient="linear(to-br, brand.300, brand.500)" size={34}>
              <ArrowBackIcon color={"white"} />
            </Circle>
          </Link>

          <Flex flexDirection={"column"} ml={3}>
            <Text
              textTransform={"capitalize"}
              fontSize={12}
              colorScheme={"gray"}
              lineHeight={1.2}
            >
              Calendario mensile
            </Text>
            <Text fontSize={18} fontWeight={"bold"} lineHeight={1.2}>
              {poiData?.adminPoi?.name?.find((n) => n.langIso === "it")
                ?.value ||
                poiData?.adminPoi?.name[0]?.value ||
                ""}
            </Text>
          </Flex>
        </Flex>
        <VStack>
          <Button
            style={{ textTransform: "capitalize" }}
            variant="ghost"
            size={"lg"}
          >
            <MowiIcon name="locator" size={35} color="gray" />
          </Button>
        </VStack>
      </Flex>
      <Card p={5}>
        <OpeningCalendar
          _id={props._id}
          getDays={getDays}
          //addRange={addRange}
          //resetCalendar={resetCalendar}
          loading={loading}
          manualStatus={poiData?.adminPoi?.statuses.manual}
        />
      </Card>
    </VStack>
  );
};

export { PoiCalendarInfos };
