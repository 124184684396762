import { CalendarDay } from "stores/queries/area";

const formatDate = (d: Date) => {
  let ye = new Intl.DateTimeFormat("it", { year: "numeric" }).format(d);
  let mo = new Intl.DateTimeFormat("it", { month: "2-digit" }).format(d);
  let da = new Intl.DateTimeFormat("it", { day: "2-digit" }).format(d);
  return `${ye}-${mo}-${da}`;
};

function isBeforeToday(date: Date): boolean {
  const Today = new Date();
  Today.setDate(Today.getDate());
  Today.setHours(0, 0, 0, 0);
  return date < Today;
}

function toCalendarDays(
  startDate: string,
  availabilityArray: (boolean | undefined | null)[]
): CalendarDay[] {
  // Funzione per aggiungere giorni alla data
  function addDays(date: Date, days: number): Date {
    const result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  }

  const transformedArray = availabilityArray.map((isOpen, index) => {
    // Calcola la data corrente partendo dalla data iniziale
    const currentDate = addDays(new Date(startDate), index);

    // Ottieni i valori per anno, mese e giorno nel fuso orario locale
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, "0");
    const day = String(currentDate.getDate()).padStart(2, "0");

    const dayString = `${year}-${month}-${day}`;

    // Determina il valore del campo "schedules"
    let schedules: string[] | null;
    if (isOpen === true) {
      schedules = ["00:00-24:00"];
    } else if (isOpen === false) {
      schedules = [];
    } else {
      schedules = null;
    }

    // Ritorna l'oggetto
    return {
      day: dayString,
      schedules: schedules,
    };
  });

  return transformedArray;
}

export { formatDate, isBeforeToday,toCalendarDays };
