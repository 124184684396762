import { useRef } from "react";
import {
  Box,
  Button,
  Checkbox,
  Circle,
  Divider,
  Flex,
  HStack,
  IconButton,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  PopoverCloseButton,
  Text,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import { ReactNode, useEffect, useState } from "react";
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
} from "@chakra-ui/react";

import {
  ArrowLeftIcon,
  ArrowRightIcon,
  CalendarIcon,
  CheckIcon,
  CloseIcon,
  InfoIcon,
  TimeIcon,
} from "@chakra-ui/icons";

import theme from "theme/theme";
import { MowiIcon, MowiIcons } from "ui/MowiIcon/Icon";

import { endOfWeek, startOfWeek } from "date-fns";
import { it } from "date-fns/locale";
import { CalendarDay, Resource } from "stores/queries/area";
import { Calendar } from "react-calendar";
import { Loading } from "ui/Loading/Loading";

import { formatDate } from "libs/helpers";
import { AddRangeMutation } from "components/Poi/types";
import { DayPage } from "./DayPage";
import { CopyPage } from "./CopyPage";
import { CalendarPage } from "./CalendarPage";
import React from "react";
import { ShepherdTour, ShepherdTourContext } from "react-shepherd";
import tourConfig from "./tutorial/OperatorSteps";
import { TourProvider, useTour } from "@reactour/tour";
import { YearPage } from "./YearPage";
import { FaArrowRight, FaArrowsAltH, FaGoogle, FaSync } from "react-icons/fa";
import {
  CgArrowsExchange,
  CgArrowsExchangeV,
  CgGoogle,
  CgSync,
} from "react-icons/cg";
import { MdOutlineCloudSync, MdSync } from "react-icons/md";
import { IoSync } from "react-icons/io5";
import { GoogleSync } from "./GoogleSync";
import { OpeningYearCalendar } from "components/Calendar/OpeningYearCalendar";

function isDateMoreThan7DaysAhead(dateToCheck: Date) {
  // Ottieni la data odierna
  const today = new Date();

  // Crea una data limite aggiungendo 7 giorni alla data odierna
  const limitDate = new Date();
  limitDate.setDate(today.getDate() + 7);

  // Confronta la data da controllare con la data limite
  return dateToCheck > limitDate;
}

type AcceptTermsMutation = ({
  variables,
}: {
  variables: { id: string };
}) => Promise<any>;

type CancelGoogleSyncMutation = ({
  variables,
}: {
  variables: { id: string };
}) => Promise<any>;

interface OpeningsViewProps {
  _id: string;
  getDays: (data: {
    variables: {
      from: string;
      to: string;
      _id: string;
    };
  }) => Promise<CalendarDay[]>;
  addRange: AddRangeMutation;
  loading: boolean;
  statusView?: ReactNode;
  permissions?: {
    calendar: boolean;
    manual: boolean;
  };
  icon: MowiIcons;
  title: string;
  name: string;
  secureRefresh: number;
  termsAccepted: number;
  termsVersion?: {
    version?: string;
    newVersion?: string;
  };
  acceptTerms: AcceptTermsMutation;
  cancelGoogleSync?: CancelGoogleSyncMutation;
  calendarMeta?: Resource["calendarMetadata"];
  calendarSync?: Resource["calendarSync"];
}

export type WeekDay = {
  date: Date;
  day: string;
  status: boolean;
  schedules: any;
  dayNum: number;
  dayName: string;
  monthName: string;
};

const OpeningsView = (props: OpeningsViewProps) => {
  const [page, setPage] = useState(0);
  const [selectedWeek, setSelectedWeek] = useState<Date>();
  const [week, setWeek] = useState([]);

  const [selDay, setSelDay] = useState<any>();

  const [dayCopy, setDayCopy] = useState(null);

  const [acceptTerms, setAcceptTerms] = useState(false);

  const selectedDays = useRef([]);

  //const [selectedDays, setSelectedDays] = useState([]);

  //const { value: days, getCheckboxProps } = useCheckboxGroup();
  const {
    isOpen: isCalOpen,
    onOpen: onCalOpen,
    onClose: onCalClose,
  } = useDisclosure();

  const {
    isOpen: isInfoOpen,
    onOpen: onInfoOpen,
    onClose: onInfoClose,
  } = useDisclosure();

  const {
    isOpen: isGOpen,
    onOpen: onGOpen,
    onClose: onGClose,
  } = useDisclosure();

  const [openGoogleSync, setOpenGoogleSync] = useState(false);

  useEffect(() => {
    setSelectedWeek(startOfWeek(new Date(), { locale: it, weekStartsOn: 1 }));
  }, []);

  useEffect(() => {
    if (!props.permissions) {
      setPage(1);
    } else {
      if (!props.permissions.calendar || !props.permissions.manual) {
        if (props.permissions.calendar) {
          setPage(1);
        } else {
          setPage(0);
        }
      }
    }
  }, [props.permissions]);

  const getWeek = () => {
    if (selectedWeek) {
      let wDate = selectedWeek;

      props
        .getDays({
          variables: {
            from: formatDate(startOfWeek(wDate, { weekStartsOn: 1 })),
            to: formatDate(endOfWeek(wDate, { weekStartsOn: 1 })),
            _id: props._id,
          },
        })
        .then((res) => {
          let data = res;

          if (!data) {
            return null;
          }

          const weekDays: WeekDay[] = [];

          for (let i = 0; i < data.length; i++) {
            const day = data[i].day;
            const schedules = data[i].schedules;
            const date = new Date(day);
            const dayName = date.toLocaleDateString("it", {
              weekday: "long",
            });
            const monthName = date.toLocaleString("it", { month: "short" });
            let status: boolean = null;

            if (schedules) {
              if (schedules.length) {
                status = true;
              } else {
                status = false;
              }
            }

            weekDays.push({
              date,
              day,
              status,
              schedules,
              dayNum: date.getDate(),
              dayName,
              monthName,
            });
          }

          setWeek(weekDays);
        });
    }
  };

  useEffect(() => {
    console.log("selected week", selectedWeek?.toLocaleDateString());
    getWeek();
  }, [selectedWeek]);

  const StatusPage = () => {
    return (
      <>
        <Flex
          maxHeight={"100%"}
          overflow={"hidden"}
          flex={1}
          maxWidth={"1200px"}
          width={"100%"}
          borderTopRadius={30}
          backgroundColor={"#fff"}
          flexDirection={"column"}
          p={8}
          alignItems={"center"}
        >
          <Text fontWeight={"bold"} fontSize={24}>
            Stato Attuale
          </Text>
          {props.statusView || null}
        </Flex>
      </>
    );
  };

  const CopyDayPage = () => {
    const [weekCopy, setWeekCopy] = useState([
      false,
      false,
      false,
      false,
      false,
      false,
      false,
    ]);

    return (
      <>
        <Flex
          maxHeight={"100%"}
          overflow={"hidden"}
          flex={1}
          maxWidth={"1200px"}
          width={"100%"}
          borderTopRadius={30}
          backgroundColor={"#fff"}
          flexDirection={"column"}
          p={8}
          py={4}
          alignItems={"center"}
        >
          <Box tour-id="copy-weekday">
            <Text fontSize={"xl"} textAlign={"center"}>
              Seleziona i giorni della settimana corrente in cui copiare gli
              orari del <b>{week[dayCopy].dayName}</b>:
            </Text>
            <VStack gap={1} mt={4} width={"100%"}>
              {week.map((day, index) => {
                if (index === parseInt(dayCopy)) return null;
                return (
                  <Button
                    key={"copyDay_" + index}
                    size={"md"}
                    width={"100%"}
                    colorScheme={weekCopy[index] ? "brand" : "blackAlpha"}
                    onClick={() => {
                      let tmpWeek = [...weekCopy];
                      tmpWeek[index] = !tmpWeek[index];
                      setWeekCopy(tmpWeek);
                    }}
                    variant={weekCopy[index] ? "solid" : "outline"}
                    tour-id={"weekday-btn"}
                  >
                    {day.dayName}
                  </Button>
                );
              })}
            </VStack>
          </Box>
        </Flex>
        <HStack
          width={"100%"}
          maxWidth={"1200px"}
          p={3}
          justifyContent="space-around"
          alignItems="space-around"
          bgColor={theme.colors.gray[100]}
        >
          <Button
            size={"lg"}
            ml={1}
            colorScheme="gray"
            onClick={() => {
              setPage(1);
            }}
            variant="outline"
            tour-id="copy-weekday-cancel"
          >
            Annulla
          </Button>
          <Button
            size={"lg"}
            ml={1}
            colorScheme="brand"
            onClick={() => {
              let range = {
                from: formatDate(week[0]?.date),
                to: formatDate(week[6]?.date),
                week: {},
              };

              for (let d = 0; d < weekCopy.length; d++) {
                if (weekCopy[d]) {
                  let dayIndex = weekCopy[d];
                  let today = week[d]?.date
                    ?.toLocaleDateString("en", {
                      weekday: "long",
                    })
                    .toLowerCase();

                  (range.week as any)[today] = week[dayCopy].schedules;
                }
              }

              props
                .addRange({
                  variables: { _ids: [props._id], range },
                })
                .then((res) => {
                  getWeek();
                });
              setPage(1);
            }}
            variant="solid"
          >
            Salva
          </Button>
        </HStack>
      </>
    );
  };

  const RenderPage = () => {
    switch (page) {
      case 0:
        return <StatusPage />;
      case 1:
        if (props.permissions) {
          return (
            <CalendarPage
              selectedDays={selectedDays}
              week={week}
              selectedWeek={selectedWeek}
              setPage={setPage}
              setSelDay={setSelDay}
              setSelectedWeek={setSelectedWeek}
              setDayCopy={setDayCopy}
              calendarMeta={props.calendarMeta}
            />
          );
        } else {
          return (
            <OpeningYearCalendar
              _id={props._id}
              addRange={props.addRange}
              getDays={props.getDays}
              calendarMeta={props.calendarMeta}
              tour-id="year-calendar"
            />
          );
        }
      case 2:
        return (
          <DayPage
            _id={props._id}
            selDay={selDay}
            addRange={props.addRange}
            setPage={setPage}
            getWeek={getWeek}
          />
        );
      case 3:
        return (
          <CopyPage
            _id={props._id}
            setPage={setPage}
            addRange={props.addRange}
            selectedDays={selectedDays}
            week={week}
            getWeek={getWeek}
          />
        );
      case 4:
        return <CopyDayPage />;
      default:
        return <StatusPage />;
    }
  };

  if (
    !props.loading &&
    (!props.termsAccepted ||
      props.termsVersion?.newVersion !== props.termsVersion?.version)
  ) {
    return (
      <Box
        width={"100%"}
        flex={1}
        flexDirection="column"
        justifyContent={"center"}
        alignItems={"center"}
        height={"100%"}
        display={"flex"}
      >
        <Flex
          flexDirection={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
          maxWidth={"1200px"}
          width={"100%"}
          p={4}
          py={6}
        >
          <Circle bgGradient="linear(to-br, brand.300, brand.500)" size={34}>
            <MowiIcon color="white" name={props.icon} size={24} />
          </Circle>
          <Flex flex={1} flexDirection={"column"} ml={3}>
            <Text
              textTransform={"capitalize"}
              fontSize={12}
              colorScheme={"gray"}
              lineHeight={1.2}
            >
              {props.title}
            </Text>
            <Text fontSize={18} fontWeight={"bold"} lineHeight={1.2}>
              {props.name}
            </Text>
          </Flex>
        </Flex>
        <Flex
          maxHeight={"100%"}
          overflow={"hidden"}
          flex={1}
          maxWidth={"1200px"}
          width={"100%"}
          p={4}
          borderTopRadius={30}
          backgroundColor={"#fff"}
          flexDirection={"column"}
          alignItems={"center"}
        >
          <Text fontSize={20} lineHeight={1.2} textAlign={"center"} mt={5}>
            {!props.termsAccepted
              ? "Per poter accedere a Mio Trentino Business è necessario leggere e accettare i seguenti"
              : "Sono disponibili nuovi termini e condizioni, leggili e accettali per accedere a Mio Trentino Business: "}{" "}
            <Link href="termini.pdf" textDecor="underline" target="_blank">
              Termini e condizioni
            </Link>
          </Text>

          <Checkbox
            checked={acceptTerms}
            onChange={(e) => {
              setAcceptTerms(e.target.checked);
            }}
            mt={5}
            borderColor={"gray.400"}
          >
            Ho letto ed accetto termini e condizioni del sito
          </Checkbox>
          {acceptTerms && (
            <Button
              onClick={() => {
                props.acceptTerms({ variables: { id: props._id } });
              }}
              size={"lg"}
              mt={6}
              colorScheme={"brand"}
            >
              Prosegui
            </Button>
          )}
        </Flex>
      </Box>
    );
  }

  return (
    <Box
      width={"100%"}
      flex={1}
      flexDirection="column"
      justifyContent={"center"}
      alignItems={"center"}
      height={"100%"}
      display={"flex"}
    >
      <Flex
        flexDirection={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
        maxWidth={"1200px"}
        width={"100%"}
        p={4}
        py={6}
      >
        {/* <TourProvider {...tourConfig.ReactTourOptions}>
          <ReactTourButton />
        </TourProvider> */}
        <Circle bgGradient="linear(to-br, brand.300, brand.500)" size={34}>
          <MowiIcon color="white" name={props.icon} size={24} />
        </Circle>
        <Flex flex={1} flexDirection={"column"} ml={3}>
          <Text
            textTransform={"capitalize"}
            fontSize={12}
            colorScheme={"gray"}
            lineHeight={1.2}
          >
            {props.title}
          </Text>
          <Text fontSize={18} fontWeight={"bold"} lineHeight={1.2}>
            {props.name}
          </Text>
        </Flex>

        {page === 1 ? (
          <HStack alignItems={"center"}>
            {/* <IconButton
              // hidden
              onClick={() => {
                if (isInfoOpen) {
                  onInfoClose();
                } else {
                  onInfoOpen();
                }
              }}
              isRound
              size={"sm"}
              variant="ghost"
              aria-label="Calendar Picker"
              fontSize="20px"
              icon={<InfoIcon color="gray" />}
            /> */}

            <Popover isOpen={isGOpen} onOpen={onGOpen} onClose={onGClose}>
              {props.permissions && (
                <PopoverTrigger>
                  <IconButton
                    /* hidden */
                    isRound
                    size={"sm"}
                    variant="ghost"
                    aria-label="Calendar Picker"
                    fontSize="20px"
                    icon={
                      <CgGoogle
                        size={26}
                        color={
                          props.calendarSync?.googleLocationId
                            ? "green"
                            : "gray"
                        }
                      />
                    }
                  />
                </PopoverTrigger>
              )}
              <PopoverContent>
                {/*   <PopoverArrow />
              <PopoverCloseButton />
              <PopoverHeader>A partire dal</PopoverHeader> */}
                <PopoverBody>
                  <VStack alignItems={"flex-start"} p={2}>
                    {!isDateMoreThan7DaysAhead(
                      new Date(props.calendarMeta?.lastDay)
                    ) ? (
                      <HStack>
                        <CgGoogle fontSize={100} color="gray" />
                        <VStack alignItems={"flex-start"}>
                          <Text fontSize={"md"} pl={2}>
                            Devi impostare il calendario per almeno i prossimi 7
                            giorni prima di attivare la sincronizzazione verso
                            Google My Business
                          </Text>
                        </VStack>
                      </HStack>
                    ) : (
                      <>
                        {props.calendarSync?.googleLocationId ? (
                          <>
                            <HStack>
                              <CgGoogle fontSize={70} color="green" />
                              <VStack alignItems={"flex-start"}>
                                <Text fontSize={"md"} pl={2}>
                                  Sincronizzazione verso Google My Business
                                  attiva
                                </Text>
                                <Text fontSize={"md"} pl={2}>
                                  Aggiornato a:{" "}
                                  {new Date(
                                    props.calendarSync?.googleLastSync
                                  ).toLocaleDateString()}
                                </Text>
                              </VStack>
                            </HStack>

                            <Button
                              w={"full"}
                              colorScheme="red"
                              mr={3}
                              mt={2}
                              onClick={() => {
                                props.cancelGoogleSync &&
                                  props.cancelGoogleSync({
                                    variables: { id: props._id },
                                  });
                              }}
                            >
                              Disattiva sincronizzazione
                            </Button>
                          </>
                        ) : (
                          <>
                            <HStack>
                              <CgGoogle fontSize={70} color="gray" />
                              <VStack alignItems={"flex-start"}>
                                <Text fontSize={"md"} pl={2}>
                                  Sincronizzazione verso Google My Business non
                                  attiva
                                </Text>
                              </VStack>
                            </HStack>

                            <Divider />

                            <Button
                              w={"full"}
                              colorScheme="brand"
                              mr={3}
                              mt={2}
                              onClick={() => {
                                setOpenGoogleSync(true);
                              }}
                            >
                              Attiva sicronizzazione{" "}
                              <ArrowRightIcon fontSize={"xl"} pl={2} />
                            </Button>
                          </>
                        )}
                      </>
                    )}
                  </VStack>
                </PopoverBody>
              </PopoverContent>
            </Popover>
            {props.permissions && (
              <Popover
                isOpen={isCalOpen}
                onOpen={onCalOpen}
                onClose={onCalClose}
              >
                <PopoverTrigger>
                  <IconButton
                    isRound
                    size={"sm"}
                    variant="ghost"
                    aria-label="Calendar Picker"
                    fontSize="20px"
                    icon={<CalendarIcon />}
                  />
                </PopoverTrigger>
                <PopoverContent>
                  {/*   <PopoverArrow />
              <PopoverCloseButton />
              <PopoverHeader>A partire dal</PopoverHeader> */}
                  <PopoverBody>
                    <Calendar
                      minDate={new Date()}
                      onChange={(date: any) => {
                        setSelectedWeek(date);
                        onCalClose();
                      }}
                      //tileContent={MiniCalendarDay}
                      //value={dateRange.start}
                    />
                  </PopoverBody>
                </PopoverContent>
              </Popover>
            )}
          </HStack>
        ) : (
          <HStack>
            {/* <IconButton
              hidden
              onClick={() => {
                if (isInfoOpen) {
                  onInfoClose();
                } else {
                  onInfoOpen();
                }
              }}
              isRound
              size={"sm"}
              variant="ghost"
              aria-label="Calendar Picker"
              fontSize="20px"
              icon={<InfoIcon color="gray" />}
            /> */}
          </HStack>
        )}
        {props.permissions && (
          <ShepherdTour steps={[]} tourOptions={tourConfig.tourOptions}>
            <StartTourButton currentPage={page} />
          </ShepherdTour>
        )}
      </Flex>

      <Box flex={1} maxWidth={"1200px"} width={"100%"}>
        {RenderPage()}
      </Box>

      {page < 2 && props.permissions?.calendar && props.permissions?.manual ? (
        <Flex
          height={"80px"}
          maxWidth={"1200px"}
          width={"100%"}
          backgroundColor={theme.colors.gray[100]}
          flexDirection={"row"}
          /*
        borderBottomRadius={30}
        overflow={"hidden"}
        marginBottom={5}
        */
        >
          <Flex
            height={"100%"}
            flex={0.5}
            justifyContent={"center"}
            alignItems={"center"}
            bgGradient={
              page === 0 ? "linear(to-l, brand.500, brand.600)" : "undefined"
            }
            color={page === 0 ? "#fff" : theme.colors.gray[600]}
            borderTopRightRadius={20}
            onClick={() => {
              setPage(0);
            }}
            className="manual-status-tab"
          >
            <Flex flexDirection={"column"} alignItems={"center"} pt={2}>
              <TimeIcon fontSize={22} mb={1} />
              <Text fontSize={14} fontWeight={"bold"}>
                Stato Manuale
              </Text>
            </Flex>
          </Flex>
          <Flex
            flex={0.5}
            justifyContent={"center"}
            alignItems={"center"}
            onClick={() => {
              setPage(1);
            }}
            bgGradient={
              page === 1 ? "linear(to-r, brand.500, brand.600)" : "undefined"
            }
            color={page === 1 ? "#fff" : theme.colors.gray[600]}
            borderTopLeftRadius={20}
            className="calendar-status-tab"
          >
            <Flex flexDirection={"column"} alignItems={"center"} pt={2}>
              <CalendarIcon fontSize={22} mb={1} />
              <Text fontSize={14} fontWeight={"bold"}>
                Calendario
              </Text>
            </Flex>
          </Flex>
        </Flex>
      ) : null}
      <HStack py={2}>
        <Link
          fontSize={12}
          pl={1}
          href="termini.pdf"
          textDecor="underline"
          target="_blank"
        >
          Privacy e Condizioni di utilizzo
        </Link>
      </HStack>
      {props.loading ? <Loading trasparent /> : null}
      <GoogleSync
        _id={props._id}
        visible={openGoogleSync}
        onClose={() => setOpenGoogleSync(false)}
      />
      <Modal isOpen={isInfoOpen} onClose={onInfoClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalBody>
            <VStack alignItems={"flex-start"} p={2}>
              <HStack>
                <VStack alignItems={"flex-start"}>
                  <Text fontSize={"md"} pl={2}>
                    © 2024 Trentino Marketing All rights reserved
                  </Text>
                  <Link
                    pl={2}
                    href="termini.pdf"
                    textDecor="underline"
                    target="_blank"
                  >
                    Termini e condizioni
                  </Link>
                </VStack>
              </HStack>
            </VStack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export { OpeningsView };

const pageToStep = (page: number) => {
  const checked = document.querySelectorAll(
    '[tour-id="weekday-checkbox"][data-checked]'
  );
  console.log("checked: ", checked.length);
  switch (page) {
    case 3:
      return "copy-period";
    case 4:
      return "copy-weekday";
    case 1: {
      if (checked.length > 0) return "copy-section";
      return "tab2";
    }
    case 2:
      return "daypage-hours";
    default:
      return 0;
  }
};
type StartTourButtonProps = {
  currentPage: number;
};
const StartTourButton = (p: StartTourButtonProps) => {
  const tour = React.useContext(ShepherdTourContext);
  const steps = React.useMemo(() => tourConfig.steps(tour), [tour]);
  tour.addSteps(steps);
  return (
    <IconButton
      aria-label="Help"
      //pos="fixed"
      size={"xxs"}
      rounded="full"
      ml={3}
      style={{ width: 25, height: 25, zIndex: 10 }}
      //p={1}
      bg="brand.500"
      /*  bottom={{ base: 130, md: undefined }}
      top={{ base: undefined, xl: 5 }}
      right={3} */
      color="white"
      //shadow={"xl"}
      onClick={() => {
        const initialStep = pageToStep(p.currentPage);
        tour.show(initialStep);
      }}
      _hover={{
        filter: "brightness(0.9)",
      }}
      icon={
        <span
          style={{
            fontSize: "1rem",
          }}
        >
          ?
        </span>
      }
    />
  );
};

const ReactTourButton = () => {
  const { setIsOpen, setCurrentStep } = useTour();
  return (
    <>
      <Box
        pos="fixed"
        top="50%"
        left="50%"
        transform="translate(-50%, -50%)"
        tour-id="center"
        className="center"
      />
      <IconButton
        aria-label="Help"
        pos="fixed"
        rounded="full"
        boxSize={"10"}
        bg="brand.500"
        top={5}
        right={5}
        color="white"
        shadow={"xl"}
        onClick={() => {
          setCurrentStep(0);
          setIsOpen(true);
        }}
        _hover={{
          filter: "brightness(0.9)",
        }}
        icon={
          <span
            style={{
              fontSize: "1.5rem",
            }}
          >
            ?
          </span>
        }
      />
    </>
  );
};
